import React from 'react';
import PropTypes from 'prop-types';
import Auth from '../modules/Auth';

import { BASE_URL } from '../consts';
import { ForgotForm } from '../components';

class ForgotPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    const storedMessage = localStorage.getItem('successMessage');
    let successMessage = '';

    if (storedMessage) {
      successMessage = storedMessage;
      localStorage.removeItem('successMessage');
    }

    this.state = {
      errors: {},
      successMessage,
      email: '',
    };

    this.processForm = this.processForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  processForm(event) {
    event.preventDefault();

    this.setState({
      errors: {},
      loading: true
    })

    const email = encodeURIComponent(this.state.email);
    const formData = `email=${email}`;

    const xhr = new XMLHttpRequest();
    xhr.open('post', `${BASE_URL}/api/v1/account/forgot_password`);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.responseType = 'json';
    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        
        localStorage.setItem('successMessage', 'Password reset request has been submitted. Please check your email.');

        this.context.router.history.push('/')
      } else {
        const errors = {};

        if (xhr.response.error && xhr.response.error === "emailNotExist" ){
          errors.summary = "Account with that email address does not exist. Please check your email.";
        } else {
          errors.summary = "Unknown error has occured. Please contact customer support.";
        }

        this.setState({ errors });
      }
    });
    xhr.addEventListener('loadend', this.setState({ loading: false }));    
    xhr.send(formData);
  }

  onChange(event) {
    const field = event.target.name;

    this.state[field] = event.target.value;
    this.setState(this.state);
  }

  render() {
    return (
      <ForgotForm
        onSubmit={this.processForm}
        onChange={this.onChange}
        errors={this.state.errors}
        state={this.state}
        loading={this.state.loading}
      />
    );
  }

}


ForgotPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default ForgotPage;
