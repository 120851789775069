import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardText } from 'material-ui/Card';
// import RaisedButton from 'material-ui/RaisedButton';
import Button from '@material-ui/core/Button';
import TextField from 'material-ui/TextField';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card: {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "rgb(255, 255, 255)",
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; */
    borderRadius: "2px",
    zIndex: "1",    
  }

});


const ForgotForm = ({
  onSubmit,
  onChange,
  errors,
  successMessage,
  state,
  classes
}) => (
  <div className={`container ${classes.card}`} style={{ paddingTop: 50      }}>

    <form action="/" onSubmit={onSubmit}>
      <h2 className="" style={{ "color": "#28429e", fontSize: 32, marginTop: '20px', marginBottom: 0,  }}>Request password reset</h2>

      {errors.summary && <p className="error-message">{errors.summary}</p>}

      <div className="">
        <TextField
          floatingLabelText="Email"
          name="email"
          errorText={errors.email}
          onChange={onChange}
          value={state.email}
        />
      </div>

      <div className="button-line">
        <Button type="submit" size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "30px 20px", padding: "5px 120px", "textTransform": "none", "borderRadius": "2px" }} >
          Submit
        </Button>

 

      </div>

      {/* <CardText>Don't have an account? <Link to={'/signup'}>Create one</Link>.</CardText> */}
    </form>
  </div>
);

export default withStyles(styles)(ForgotForm);
