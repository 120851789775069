import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { LoaderCard, ProfileField, ProfileButton, AlertWrapper } from '../elements'

import Auth from '../modules/Auth';
import Lang from '../modules/Lang';

import { fetchSubmit } from '../utils';
import { BASE_URL } from '../consts';

const styles = theme => ({

});


class Profile extends React.Component {
  state = {

  }

  handleChange = name => event => {
    this.this.setState({ [name]: event.target.value });
  }
  handleMultipleChange = name => (event, index) => {
    if (typeof this.state[name] !== "object" ){ this.state[name] = []; }
    this.state[name][index] = event.target.value;

    this.this.setState({ [name]: this.state[name] });
  }  
  handleCheck = name => value => event => {
    this.this.setState({ [name]: value === this.state[name] ? null : value });
  };  
  onDismiss() {
    this.setState({ alert: false, alertContents: "", alertStatus: "", });
  }  

  submitProfileChange = () => {
    const h = this.context.router.history;

    const { storage } = this.props;
    const { language } = storage;
    const t = Lang[language];

    fetchSubmit(h)(
      { 
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
      },
      `${BASE_URL}/api/v1/account/update`,
      () => this.setState({ loading: true }),
      data => { 
        if (data.validationErrors){
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.validationErrors.map(e=>e.msg).reduce((acc, cur) => { return `${acc}\n${cur} `}, "")
          })    
        } else if (data.err) {
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.err
          })               
        } else if (!data.output){
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: t['profile.user.message.unknown_error']
          })             
        } else {
          this.setState({ 
            alert: true,
            alertStatus: "success",
            alertContents: t['profile.user.message.success']
          })               
        }
      },
      e => {
        console.log(e);
        this.setState({ 
          alert: true,
          alertStatus: "danger",
          alertContents: e
        })          
      },
      () => this.setState({ loading: false }),
    )
    
  }

  submitPasswordChange = () => {
    const h = this.context.router.history;

    const { storage } = this.props;
    const { language } = storage;
    const t = Lang[language];

    fetchSubmit(h)(
      { 
        password: this.state.password,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,        
      },
      `${BASE_URL}/api/v1/account/update_password`,
      () => this.setState({ loading: true }),
      data => { 
        if (data.validationErrors){
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.validationErrors.map(e=>e.msg).reduce((acc, cur) => { return `${acc}\n${cur} `}, "")
          })    
        } else if (data.err) {
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.err
          })               
        } else if (!data.output){
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: t['profile.password.message.unknown_error']
          })             
        } else {
          this.setState({ 
            alert: true,
            alertStatus: "success",
            alertContents: t['profile.password.message.success']
          })               
        }
      },
      e => {
        console.log(e);
        this.setState({ 
          alert: true,
          alertStatus: "danger",
          alertContents: e
        })          
      },
      () => this.setState({ loading: false }),
    )
    
  }

  render(){
    const h = this.context.router.history;
    const { classes, storage } = this.props;
    const { loading } = this.state;

    const { language } = storage;
    const t = Lang[language];

    const { profile, email } = Auth.getUser();

    return (
      <div>
        <AlertWrapper 
          alert={this.state.alert} 
          status={this.state.alertStatus} 
          contents={this.state.alertContents}
          dismissFn={this.onDismiss.bind(this)} 
        />
        <LoaderCard loading={loading}>  
          <Card className="container" style={{ width: "85%", "textAlign": "left", padding: "40px 45px" }}>

            <Typography style={{ fontSize: 14, textTransform: "uppercase", }} gutterBottom >
              {t['profile.user.header']}
            </Typography>    

            <Typography style={{ fontSize: 14 }} gutterBottom >
              <b>{t['profile.user.name.header']}:</b> {profile.name}
            </Typography>     
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <b>{t['profile.user.email.header']}:</b> {email}
            </Typography>     
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <b>{t['profile.user.phone.header']}:</b> {profile.phone}
            </Typography>                             
                 
            <hr style={{ borderTop: "1px solid #28429e", marginTop: 30 }} />                 

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ProfileField 
                required
                label={t['profile.user.name.title']}
                placeholder={t['profile.user.name.placeholder']}
                onChangeFn={ event => { this.setState({ name: event.target.value }) } }
              />
              <ProfileField 
                required
                type={"email"}
                label={t['profile.user.email.title']}
                placeholder={t['profile.user.email.placeholder']}
                onChangeFn={ event => { this.setState({ email: event.target.value }) } }
              />
              <ProfileField 
                required
                label={t['profile.user.phone.title']}
                placeholder={t['profile.user.phone.placeholder']}
                onChangeFn={ event => { this.setState({ phone: event.target.value }) } }
              />  
            </div>

            <ProfileButton title={t['profile.user.submit.title']} submitFn={this.submitProfileChange} />

            <hr style={{ borderTop: "1px solid #28429e", marginTop: 30 }} />

            <Typography style={{ fontSize: 14, textTransform: "uppercase", }} gutterBottom >
              {t['profile.password.header']}
            </Typography>              

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ProfileField 
                required
                type={"password"}
                label={t['profile.password.current.title']}
                placeholder={t['profile.password.current.placeholder']}
                onChangeFn={ event => { this.setState({ password: event.target.value }) } }
              />
              <ProfileField 
                required
                type={"password"}
                label={t['profile.password.new.title']}
                placeholder={t['profile.password.new.placeholder']}
                onChangeFn={ event => { this.setState({ newPassword: event.target.value }) } }
              />
              <ProfileField 
                required
                type={"password"}
                label={t['profile.password.confirm.title']}
                placeholder={t['profile.password.confirm.placeholder']}
                onChangeFn={ event => { this.setState({ confirmPassword: event.target.value }) } }
              />                  
            </div>
     
            <ProfileButton title={t['profile.password.submit.title']} submitFn={this.submitPasswordChange} />     

          </Card> 
        </LoaderCard>
      </div> 
    )
  }
};


Profile.contextTypes = {
  router: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(Profile));
