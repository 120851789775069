import React from 'react';
import Card from '@material-ui/core/Card';

export default class LoaderCard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { loading } = this.props;

    return (
      loading ? <Card className="container" style={{ width: "85%", "textAlign": "left", padding: "40px 45px" }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, color: "#28429e", }}>
          <i className="fas fa-spinner fa-10x fa-pulse"></i>
        </div>
      </Card> : this.props.children
    );
  }

}
