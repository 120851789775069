import React from 'react';
import Auth from '../modules/Auth';

import { Contact } from '../components';

class ContactPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {

  }

  render() {
    return (<Contact />);
  }

}

export default ContactPage;
