import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';

import Dropzone from 'react-dropzone'
import {useDropzone} from 'react-dropzone'


import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import Lang from '../modules/Lang';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    // width: "50%"
  },  
});

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',   
  flexDirection: 'column', 
  position: 'relative',
  zIndex: 1000
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
}

const img = {
  display: 'block',
  width: 'auto',
  maxWidth: 90,
  height: 90
}



class Confirm extends React.Component {

  render(){

    const { state, setState, organizations, submitApplication, storage } = this.props;

    const { language } = storage;
    const t = Lang[language];

    const thumbs = state.files.map(file => {

      return <div style={{
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 60,
        height: 60,
        padding: 4,
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',   
        flexDirection: 'column', 
        position: 'relative',
        zIndex: 1000

      }} key={file.name+Math.random()}>
        { file.type.includes("image") ?
          <div style={thumbInner}>
            <i className="far fa-file-image fa-2x" style={{ color: '#ddd'}} />
          </div>
        : file.type.includes("pdf") ?
          <div style={thumbInner}>
            <i className="far fa-file-pdf fa-2x" style={{ color: '#ddd'}} />
          </div>
        :
          <div style={thumbInner}>
            <i className="far fa-file fa-2x" style={{ color: '#ddd'}} />
          </div>        
        }      
        
        <div style={{ width: 60, position: 'absolute', bottom: 5, left: 0, right: 0, textAlign: 'center' }}>
          <p style={{ fontSize: 10, margin: 0, overflow: "hidden", "textOverflow": "ellipsis", overflow: 'hidden', lineHeight: 1, }}>{file.name}</p>
        </div>
      </div>
    })    


    return (
      <Card className="container" style={{ width: "90%", "textAlign": "left", padding: "50px 55px", }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <Typography style={{ fontSize: 16, color: "#28429e",  textTransform: "uppercase", paddingBottom: 10, margin: 0 }} gutterBottom >{t['confirm.applicant.title']}
            </Typography> 
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.name.title']}:</span> {state['applicant.name']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.organization.title']}:</span> {state['applicant.organization']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.invoice_declaration.title']}:</span> {state['applicant.invoice_declaration']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.invoice_address.title']}:</span> {state['applicant.invoice_address']}
            </Typography>                                        
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.address.title']}:</span> {state['applicant.address']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.zip.title']}:</span> {state['applicant.zip']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.city.title']}:</span> {state['applicant.city']}
            </Typography>        
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.contact.title']}:</span> {state['applicant.contact']}
            </Typography>                
            {/*<Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.phone_vaxel.title']}:</span> {state['applicant.phone_vaxel']}
            </Typography>   
            */}       
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.phone.title']}:</span> {state['applicant.phone']}
            </Typography>          
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.applicant.email.title']}:</span> {state['applicant.email']}
            </Typography>                                        

            <Typography style={{ fontSize: 16, color: "#28429e",  textTransform: "uppercase", padding: "10px 0", margin: 0 }} gutterBottom >{t['confirm.contractor.title']}
            </Typography> 
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contractor.name.title']}:</span> {state['contractor.name']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contractor.organization.title']}:</span> {state['contractor.organization']}
            </Typography>            
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contractor.address.title']}:</span> {state['contractor.address']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contractor.zip.title']}:</span> {state['contractor.zip']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contractor.city.title']}:</span> {state['contractor.city']}
            </Typography>        
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contractor.contact.title']}:</span> {state['contractor.contact']}
            </Typography>                
            {/*<Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contractor.phone_vaxel.title']}:</span> {state['contractor.phone_direct']}
            </Typography>       
            */}   
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contractor.phone.title']}:</span> {state['contractor.phone']}
            </Typography>          
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contractor.email.title']}:</span> {state['contractor.email']}
            </Typography>    




            <Typography style={{ fontSize: 16, color: "#28429e", textTransform: "uppercase", padding: "10px 0", margin: 0 }} gutterBottom >{t['confirm.subcontractor.title']}
            </Typography> 
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.subcontractor.name.title']}:</span> {state['subcontractor.name']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.subcontractor.organization.title']}:</span> {state['subcontractor.organization']}
            </Typography>            
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.subcontractor.address.title']}:</span> {state['subcontractor.address']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.subcontractor.zip.title']}:</span> {state['subcontractor.zip']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.subcontractor.city.title']}:</span> {state['subcontractor.city']}
            </Typography>        
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.subcontractor.contact.title']}:</span> {state['subcontractor.contact']}
            </Typography>                
            {/*<Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.subcontractor.phone_vaxel.title']}:</span> {state['subcontractor.phone_direct']}
            </Typography>       
            */}   
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.subcontractor.phone.title']}:</span> {state['subcontractor.phone']}
            </Typography>          
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.subcontractor.email.title']}:</span> {state['subcontractor.email']}
            </Typography>   




            <Typography style={{ fontSize: 16, color: "#28429e", textTransform: "uppercase", padding: "10px 0", margin: 0 }} gutterBottom >{t['confirm.contract.title']}
            </Typography>                 
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contract.building.title']}:</span> {state['contract.building']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contract.work_area.title']}:</span> {state['contract.work_area']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contract.project_number.title']}:</span> {state['contract.project_number']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contract.agreement_date.title']}:</span> {state['contract.agreement_date']}
            </Typography>
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contract.sum.title']}:</span> {state['contract.sum'].replace(/(.)(?=(.{3})+$)/g,"$1 ")}
            </Typography>        
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contract.concerns.title']}:</span> {state['contract.concerns']}
            </Typography>                
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contract.start_date.title']}:</span> {state['contract.start_date']}
            </Typography>          
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <span style={{ fontWeight: 'bold' }}>{t['confirm.contract.end_date.title']}:</span> {state['contract.end_date']}
            </Typography>          
          </div>

          <div style={{ width: '50%' }}>
            <Typography style={{ fontSize: 26, color: "#28429e",  textTransform: "uppercase", paddingBottom: 35, margin: 0 }} gutterBottom >{t['confirm.summary.title']}
            </Typography>   
            <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
              <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                {t['confirm.summary.project_number']}
              </Typography>  
              <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                {state['contract.project_number']}
              </Typography>              
            </div>   

            <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
              <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                {t['confirm.summary.contractor']}
              </Typography>  
              <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                {state['contractor.name']}
              </Typography>              
            </div>        

            <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
              <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                {t['confirm.summary.sum']}
              </Typography>  
              <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                {state['contract.sum'].replace(/(.)(?=(.{3})+$)/g,"$1 ")}
              </Typography>              
            </div>                      

            { state['guarantee.while_contract'] &&
              <div>
                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    {t['confirm.summary.while_contract.type']}
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    <i className="fas fa-flag" style={{ color: '#28429e'}} /> {t['confirm.summary.while_contract.type_name']}
                  </Typography>              
                </div>   

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    {t['confirm.summary.while_contract.start_date']}
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    {state['guarantee.while_contract_start_date']}
                  </Typography>              
                </div>     

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    {t['confirm.summary.while_contract.end_date']}
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    {state['guarantee.while_contract_end_date']}
                  </Typography>              
                </div>   

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    {t['confirm.summary.while_contract.amount']}
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    {state['guarantee.while_contract_amount_percent']}% {t['confirm.summary.while_contract.of_sum']}
                  </Typography>              
                </div>                

              </div>
            }   

            { state['guarantee.while_warranty'] &&
              <div>
                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    {t['confirm.summary.while_warranty.type']}
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    <i className="fas fa-flag" style={{ color: '#28429e'}} /> {t['confirm.summary.while_warranty.type_name']}
                  </Typography>              
                </div>   

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    {t['confirm.summary.while_contract.start_date']}
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    {state['guarantee.while_warranty_start_date']}
                  </Typography>              
                </div>     

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    {t['confirm.summary.while_contract.end_date']}
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    {state['guarantee.while_warranty_end_date']}
                  </Typography>              
                </div>   

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    {t['confirm.summary.while_warranty.amount']}
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    {state['guarantee.while_warranty_amount_percent']}% {t['confirm.summary.while_warranty.of_sum']}
                  </Typography>              
                </div>   

              </div>
            }            

            <div style={{ width: '100%', padding: "4px 0", marginBottom: 9 }}>
              <Typography style={{ fontSize: 16, width: '100%' }} gutterBottom >
                {t['confirm.summary.attachments.title']}
              </Typography>  
              <div style={{ fontSize: 16, width: '100%' }} >

                { thumbs.length 
                  ? 
                  <div>
                    {thumbs}
                  </div>
                  :
                  <div>
                    <p style={{ fontSize: 12 }}>{t['confirm.summary.attachments.no_selected.title']}</p>
                  </div>          
                } 
            
              </div>              
            </div>            

          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <Button onClick={() => setState({ route: "form", submit: false }) } size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "30px 0px", padding: "5px 100px", "textTransform": "none", "borderRadius": "2px" }} >
              {t['confirm.submit.back']}
            </Button>              
          </div>
          <div style={{ width: '50%', alignItems: 'flex-end', textAlign: 'end' }}>
            <Button onClick={() => { 
              submitApplication()
            } } size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "30px 0px", padding: "5px 120px", "textTransform": "none", "borderRadius": "2px" }} >
              {t['confirm.submit.title']}
            </Button>        
          </div>    
        </div>        
      </Card> )

    }
};


Confirm.contextTypes = {
  router: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};
const mapDispatchToProps = (dispatch) => ({

});

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(Confirm));