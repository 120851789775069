import React from 'react';

const thumbInner = {
  display: 'flex',
  minWidth: 0,
}

export default class ThumbIcon extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    return (
      <div style={thumbInner}>
        <i className={`far ${this.props.icon} fa-2x`} style={{ color: '#ddd'}} />
      </div>
    );
  }

}
