import React from 'react';

import Button from '@material-ui/core/Button';


export default class ProfileButton extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { submitFn, title } = this.props;

    return (

      <div style={{ "justifyContent": "flex-start", display: "flex" }}>
        <Button onClick={() => { 
          submitFn();
        } } size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "40px 0px", padding: "5px 120px", "textTransform": "none", "borderRadius": "2px" }} >
            { title }
        </Button>
      </div>        
          

    );
  }

}
