import React from 'react';
import PropTypes from 'prop-types';

import { generate_xhr } from '../utils';
import { BASE_URL } from '../consts';



import { Dashboard } from '../components';

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: []
    };
  }

  check_load(){
    return  this.state['loading.applications']
  }

  componentDidMount() {
    const h = this.context.router.history;
    generate_xhr(h)(
      'get', 
      `${BASE_URL}/api/v1/applications`, 
      xhr => e => this.setState({ 'loading.applications': true  }),
      xhr => e => {
        if (xhr.status === 200) {
          const applications = JSON.parse(xhr.response)
          this.setState({
            applications,
          });
        }
      },
      xhr => e => this.setState({ 'loading.applications': false  }),
    )
  }

  render() {
    const { applications, } = this.state;
    return (<Dashboard applications={applications} loading={this.check_load()} />);
  }

}

DashboardPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default DashboardPage;
