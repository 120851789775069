import React, { createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Cookies from 'universal-cookie';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import { Alert } from 'reactstrap';

import { BASE_URL } from '../consts';
import Lang from '../modules/Lang';
import Auth from '../modules/Auth';

import { ApplicationTable, Loader } from '../elements';


const styles = theme => ({

});

const url = "http://localhost:4000/api/v1/applications/export/5dd6858f94f05a05dc3d810d"

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organization: Auth.getUser() && Auth.getUser().organization[0] && Auth.getUser().organization[0]._id,
      search: "",
      alert: false
    }
  } 

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  onDismiss() {
    this.setState({ alert: false, alertContents: "", alertStatus: "", });
  }  

  render(){

  const h = this.context.router.history;

    const { classes, storage, loading, applications } = this.props;
    const { search } = this.state;
    const { language } = storage;
    const t = Lang[language];

    const { organization, role } = Auth.getUser();

    const filteredApplications = applications.filter(a => { 
      return a.organization._id === this.state.organization 
    }).filter(a=>{
      const _s = search.trim().toLowerCase();

      return !search ||
        a._id.trim().toLowerCase().includes(_s) ||
        a.applicant.name.trim().toLowerCase().includes(_s) ||
        a.contract.project_number.trim().toLowerCase().includes(_s) ||
        a.contract.building.trim().toLowerCase().includes(_s) ||
        a.start_date.trim().toLowerCase().includes(_s) || 
        a.contractor.name.trim().toLowerCase().includes(_s) ||
        (a.subcontractor && a.subcontractor.name && a.subcontractor.name.trim().toLowerCase().includes(_s) ) ||
        (a.policy && a.policy.originalname.trim().toLowerCase().includes(_s)) || 
        (a.invoice && a.invoice.originalname.trim().toLowerCase().includes(_s)) ||
        a.insurance_number && a.insurance_number.trim().toLowerCase().includes(_s) ||
        a.control_number && a.control_number.trim().toLowerCase().includes(_s)
    })

    // console.log(this.state.organization )

  return (
    <div>

      { this.state.alert && 
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', position: 'fixed', width: '100%', zIndex: 2001, top: 0, textAlign: 'center'}}>
          <Alert style={{ width: '100%' }} color={this.state.alertStatus} isOpen={this.state.alert} toggle={this.onDismiss.bind(this)}>
            { this.state.alertContents }
          </Alert>
        </div>
      }
      <Card className="container" style={{ width: "85%", "textAlign": "left", padding: 20 }}>


        <Loader loading={loading} >
          <div style={{  }}>


            { role.name === "broker" && this.state.organization && 
              <FormControl style={{ padding: "0 0px", margin: "0 8px", display: 'flex', flexDirection: 'column' }}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  {t['dashboard.organizationFilter.title']}
                </InputLabel>
                <NativeSelect
                  value={this.state["organization"]}
                  onChange={this.handleChange('organization')}
                  input={<Input id="age-native-label-placeholder" />}
                >
                  {organization.map(o=>{
                    return <option key={o._id} value={o._id}>{o.name}</option>
                  })}
                </NativeSelect>
              </FormControl>
            }



            <FormControl style={{ padding: "0 0px", margin: "30px 20px", display: 'flex', flexDirection: 'row' }}>

              <FormControl style={{ width: '50%' }}>
                <div>
                  <Button size="large" variant="contained" color="primary" style={{ fontSize: 20, "textTransform": "none", "borderRadius": "2px", marginBottom: "1%", width: '60%'  }} onClick={()=>h.push('/account/new')} >
                      {t['dashboard.application.new']}
                  </Button>
                </div>

                { role.name === "broker" && 
                <AuthenticatedLink organization={this.state.organization} setState={this.setState.bind(this)} url={`${BASE_URL}/api/v1/applications/export/${this.state.organization}`} filename='data.xlsx' >
                  <Button size="large" variant="contained" color="primary" style={{ fontSize: 20, "textTransform": "none", "borderRadius": "2px", width: '60%' }}  >
                    {t['dashboard.application.export']}
                  </Button>
                </AuthenticatedLink>
                }

              </FormControl>

              <FormControl style={{ width: '50%' }}>
                <TextField
                  value={search}
                  // defaultValue={defaultValue}
                  type={"text"}

                  // label={"Search"}
                  style={{ margin: "8px 0" }}
                  placeholder={"Search..."}

                  fullWidth
                  margin="normal"

                  InputLabelProps={{
                    shrink: true,
                  }}

                  onChange={this.handleChange('search')}

                /> 
              </FormControl>          
            
            </FormControl>

            <Typography style={{ fontSize: 18, padding: "0 20px" }} color="textSecondary" gutterBottom >
              {t['dashboard.application.unconfirmed.title']}
            </Typography>    
            <ApplicationTable applications={filteredApplications.filter(a=> a.status === undefined )} />       

            <Typography style={{ fontSize: 18, padding: "0 20px" }} color="textSecondary" gutterBottom >
              {t['dashboard.application.confirmed.title']}
            </Typography>    
            <ApplicationTable applications={filteredApplications.filter(a=> a.status && !a.paymentAt )} />    

            <Typography style={{ fontSize: 18, padding: "0 20px" }} color="textSecondary" gutterBottom >
              {t['dashboard.application.paid.title']}
            </Typography>    
            <ApplicationTable applications={filteredApplications.filter(a=> a.status && a.paymentAt && moment().isBefore(a.end_date) )} />    

            <Typography style={{ fontSize: 18, padding: "0 20px" }} color="textSecondary" gutterBottom >
              {t['dashboard.application.expired.title']}
            </Typography>    
            <ApplicationTable applications={filteredApplications.filter(a=> a.status && moment().isAfter(a.end_date) )} />

            <Typography style={{ fontSize: 18, padding: "0 20px" }} color="textSecondary" gutterBottom >
              {t['dashboard.application.rejected.title']}
            </Typography>    
            <ApplicationTable applications={filteredApplications.filter(a=> a.status === false )} />   

          </div>
        </Loader>
      </Card> 
    </div>)
    }
};

Dashboard.contextTypes = {
  router: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  
});

const AuthenticatedLink = ({ url, filename, children, setState, organization }) => {
  const link = createRef()
  // console.log("Tester")
  
  const handleAction = async (e) => {
    // console.log(link.current.organization)
    // e.preventDefault();

    if (link.current.href && link.current.organization === organization ) { 
      // link.current.href = "";
      // link.current.download = "";
      return;
    }
    
    e.preventDefault();
  
    fetch(url, { 
      headers: {...{ 'Authorization': 'Bearer ' + Auth.getToken() }}
    }).then(async (result) => {

      // console.log(result)

      if (result.ok){
        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)
        
        // console.log(result, blob, href)

        link.current.download = filename;
        link.current.href = href;
        link.current.organization = organization
          
        link.current.click()  

        // link.current.href = "";
        // link.current.download = "";

      } else {
        const data = await result.json();
        // console.log(data)   
        setState({ 
          alert: true,
          alertStatus: "danger",
          alertContents: data.err
        }) 
      }

    })
    

  }

  return (
    <div>
      <a role='button' ref={link} onClick={handleAction}>{children}</a>
    </div>
  )
}

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(Dashboard));