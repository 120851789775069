import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Auth from '../modules/Auth';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import Dropzone from 'react-dropzone'
import {useDropzone} from 'react-dropzone'

import { fetchSubmit } from '../utils';
import { BASE_URL } from '../consts';

import Lang from '../modules/Lang';

import { Alert } from 'reactstrap';

import { ViewList, AddCircleOutline, Mail, Flag, AccountCircle, Settings } from '@material-ui/icons';

const styles = theme => ({
  icon: {
    width: 90, 
    height: 90, 
    color: "#28429e"
  },  
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    // width: "50%"
  },  
});


class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: "",
      message: "",
    }    
  }  

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  onDismiss() {
    this.setState({ alert: false, alertContents: "", alertStatus: "", });
  }  

  submitContact = () => {
    const h = this.context.router.history;
    fetchSubmit(h)(
      { 
        message: this.state.message,
        subject: this.state.subject,
      },
      `${BASE_URL}/api/v1/contact/new`,
      () => this.setState({ loading: true }),
      data => { 
        console.log(data) 

        if (data.validationErrors){
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.validationErrors.map(e=>e.msg).reduce((acc, cur) => { return `${acc}\n${cur}`}, "")
          })    
        } else if (data.err) {
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.err
          })    
        } else if (!data.output){
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: "An error has occured while submitting the message. Please contact customer support if the error persist."
          })             
        } else {
          this.setState({ 
            alert: true,
            alertStatus: "success",
            alertContents: "Your message has been successfully submitted."
          })               
        }
      },
      e => {
        console.log(e);
        this.setState({ 
          alert: true,
          alertStatus: "danger",
          alertContents: e
        })          
      },
      () => this.setState({ loading: false }),
    )
    
  }

  render(){
    const h = this.context.router.history;
    const { classes, storage } = this.props;
    const { loading } = this.state;

    const { language } = storage;
    const t = Lang[language];

    return (
      <div>
        { this.state.alert && 
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', position: 'fixed', width: '100%', zIndex: 2001, top: 0, textAlign: 'center'}}>
            <Alert style={{ width: '100%' }} color={this.state.alertStatus} isOpen={this.state.alert} toggle={this.onDismiss.bind(this)}>
              { this.state.alertContents }
            </Alert>
          </div>
        }
        { loading ?

          <Card className="container" style={{ width: "85%", "textAlign": "left", padding: "40px 45px" }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, color: "#28429e", }}>
              <i className="fas fa-spinner fa-10x fa-pulse"></i>
            </div>
          </Card> 
          :          
          <Card className="container" style={{ width: "85%", "textAlign": "left", padding: "40px 45px" }}>
              
            <div style={{ display: 'flex', paddingBottom: 10 }}>
              <AccountCircle className={classes.icon} />
              <div style={{ padding: 10 }}>
                <Typography style={{ fontSize: 14 }} gutterBottom >
                  {t['contact.intro']} <b>{t['contact.name']}</b>. 
                </Typography>     
                <Typography style={{ fontSize: 14 }} gutterBottom >
                  {t['contact.incase']}:
                </Typography>
                <Typography style={{ fontSize: 14 }} gutterBottom >
                  <i className="fas fa-envelope" style={{ paddingRight: 10}} /><b>{t['contact.email']}</b> 
                  <span style={{ padding: "0 10px"}}>{t['contact.or']}</span>
                  <i className="fas fa-phone" style={{ paddingRight: 10}} /><b>{t['contact.phone']}</b>     
                </Typography> 
              </div>
            </div>

            <Typography style={{ fontSize: 14 }} gutterBottom >
              <b>{t['contact.from']}:</b> {Auth.getUser().email}
            </Typography>     
            <Typography style={{ fontSize: 14 }} gutterBottom >
              <b>{t['contact.to']}:</b> {t['contact.email']}
            </Typography>             

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                required
                type="text"
                label={t['contact.subject.title']}
                style={{ margin: "8px 0", width: '50%' }}
                placeholder={t['contact.subject.placeholder']}
                // fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleChange('subject')}
              /> 

              <TextField
                placeholder="MultiLine with rows: 2 and rowsMax: 4"
                multiline={true}
                rows={6}
                rowsMax={6}

                required
                type="text"
                label={t['contact.message.title']}
                style={{ margin: "8px 0", width: '50%' }}
                placeholder={t['contact.message.placeholder']}
                // fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleChange('message')}
              /> 
            </div>

            <div style={{ "justifyContent": "flex-start", display: "flex" }}>
              <Button onClick={() => { 
                console.log(this.state)
                this.submitContact();
              } } size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "50px 0px", padding: "5px 120px", "textTransform": "none", "borderRadius": "2px" }} >
                    {t['contact.submit.title']}
              </Button>
            </div>        

          </Card>           

        }

      </div>
    )

  }
};

Contact.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(Contact));