import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardText } from 'material-ui/Card';
// import RaisedButton from 'material-ui/RaisedButton';
import Button from '@material-ui/core/Button';
import TextField from 'material-ui/TextField';

import { withStyles } from '@material-ui/core/styles';

import { BASE_URL } from '../consts';

const styles = theme => ({
  card: {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "rgb(255, 255, 255)",
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; */
    borderRadius: "2px",
    zIndex: "1",    
  }

});












const LoginForm = ({
  onSubmit,
  onChange,
  errors,
  successMessage,
  user,
  classes
}) => (
  <div className={`container ${classes.card}`} style={{ paddingTop: 50      }}>

    <img src={'/img/RSG-Nordics-Logo-SVG.svg'} style={{"height": 180 }}/>

    <form action="/" onSubmit={onSubmit}>
      <h2 className="" style={{ "color": "#28429e", fontSize: 32, marginTop: '20px', marginBottom: 0, paddingTop: 75 }}>Välkommen till RSG Construction & Specialty</h2>

      {errors.summary && <p className="error-message">{errors.summary}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}

      <div className="">
        <TextField
          floatingLabelText="Email"
          name="email"
          errorText={errors.email}
          onChange={onChange}
          value={user.email}
        />
      </div>

      <div className="">
        <TextField
          floatingLabelText="Password"
          type="password"
          name="password"
          onChange={onChange}
          errorText={errors.password}
          value={user.password}
        />
      </div>

      <div className="button-line">
        <a href="/forgot" onClick={ (e) => { e.preventDefault(); window.location.replace(`/forgot`) } }  className="" style={{ "color": "#28429e", fontSize: 16, marginBottom: 0, fontWeight: 400  }}>Glömt ditt password?</a>
      </div>

      <div className="button-line">

        <Button type="submit" size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "0px 20px", padding: "5px 120px", "textTransform": "none", "borderRadius": "2px" }} >
          Logga in
        </Button>

      </div>

      {/* <CardText>Don't have an account? <Link to={'/signup'}>Create one</Link>.</CardText> */}
    </form>
  </div>
);

export default withStyles(styles)(LoginForm);
