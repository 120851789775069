import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { unflatten } from 'flat';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import Form from "./Form";
import Confirm from "./Confirm";
import Thankyou from "./Thankyou";

import Auth from '../modules/Auth';
import Lang from '../modules/Lang';

import { formDataSubmit } from '../utils';
import { BASE_URL } from '../consts';

import { Alert } from 'reactstrap';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    // width: "50%"
  },  
});


class New extends React.Component {
  constructor(props) {
    super(props);

    const user = Auth.getUser();
    const o = user.organization && user.organization[0];

    // console.log(user);

    this.state = {
      route: "form",
      order: {},
      files: [],

      organization: o && o._id,

      "applicant.name": o && o.name || "",
      "applicant.organization": o && o.business_id || "",
      "applicant.address": o && o.street_address || "",
      "applicant.invoice_declaration": o && o.invoice_declaration || "",
      "applicant.invoice_address": o && o.invoice_address || "",
      "applicant.zip": o && o.zip || "",
      "applicant.city": o && o.city || "",
      "applicant.contact": [user.profile.name],
      // "applicant.phone_direct": "",
      "applicant.phone_vaxel": "",
      "applicant.phone_mobile": "",
      "applicant.phone": user.profile.phone,
      "applicant.email": user.email,

      "contractor.name": "",
      "contractor.organization": "",
      "contractor.address": "",
      "contractor.zip": "",
      "contractor.city": "",
      "contractor.contact": [""],
      // "contractor.phone_direct": "",
      "contractor.phone_vaxel": "",
      "contractor.phone_mobile": "",
      "contractor.phone": "",
      "contractor.email": "",   

      "subcontract": false,

      "subcontractor.name": "",
      "subcontractor.organization": "",
      "subcontractor.address": "",
      "subcontractor.zip": "",
      "subcontractor.city": "",
      "subcontractor.contact": [""],
      // "subcontractor.phone_direct": "",
      "subcontractor.phone_vaxel": "",
      "subcontractor.phone_mobile": "",
      "subcontractor.phone": "",
      "subcontractor.email": "",            
   
      "contract.building": "",
      "contract.work_area": "",
      "contract.project_number": [""],
      "contract.agreement_date": moment().format("YYYY-MM-DD"),
      "contract.sum": "",
      "contract.concerns": "",
      "contract.start_date": moment().format("YYYY-MM-DD"),   
      "contract.end_date": moment().add(1, 'day').format("YYYY-MM-DD"),      

      'guarantee.while_contract': false,
      'guarantee.while_contract_amount_percent': "",
      'guarantee.while_warranty': false,  
      'guarantee.while_warranty_amount_percent': "",
      'guarantee.while_warranty_period': "",


      alert: false,
      alertContents: "",
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  handleChange = name => event => {

    this.setState({ [name]: event.target.value });
  }

  handleMultipleChange = name => (event, index) => {
    if (typeof this.state[name] !== "object" ){ this.state[name] = []; }
    this.state[name][index] = event.target.value;

    this.setState({ [name]: this.state[name] });
  }  

  onDismiss() {
    this.setState({ alert: false, alertContents: "" });
  }

  handleCheck = name => value => event => {
    this.setState({ [name]: value === this.state[name] ? "" : value });
  };  

  submitApplication = () => {

    const { storage } = this.props;
    const { language } = storage;
    const t = Lang[language];


    const application = {...this.state}
    const files = [...this.state.files]

    delete application.submit;
    delete application.route;
    delete application.alert;
    delete application.alertContents;
    delete application.files;
    
    delete application.order;

    if (!application.subcontract){
      delete application["subcontractor.name"]
      delete application["subcontractor.organization"]
      delete application["subcontractor.address"]
      delete application["subcontractor.zip"]
      delete application["subcontractor.city"]
      delete application["subcontractor.contact"]
      delete application["subcontractor.phone_vaxel"]
      delete application["subcontractor.phone_mobile"]
      delete application["subcontractor.phone"]
      delete application["subcontractor.email"]
    }

    // application.test = true;
    
    application.language = language || "en";

    var formData  = new FormData();

    Object.keys(application).forEach( key => formData.append(key, application[key]) )
    files.forEach( file => formData.append('files', file ) )

    const h = this.context.router.history;
    formDataSubmit(h)(
      formData,
      `${BASE_URL}/api/v1/applications/new`,
      () => this.setState({ loading: true }),
      data => { 
        // console.log(data) 

        if (data.validationErrors){
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.validationErrors.map(e=>e.msg).reduce((acc, cur) => { return `${acc}\n${cur}`}, "")
          })    
        } else if (data.err) {
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.err
          })    
        } else if (!data.output){
          this.setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: t['new.application.message.unknown_error']
          })             
        } else {
          this.setState({ 
            alert: true,
            alertStatus: "success",
            alertContents: t['new.application.message.success'],
            order: data,
            route: "thankyou"            
          })               
        }
      },
      e => {
        console.log(e);
        this.setState({ 
          alert: true,
          alertStatus: "danger",
          alertContents: e
        })          
      },
      () => this.setState({ loading: false }),
    )
    
  }


  render(){
  	const h = this.context.router.history;
  	const { classes, organizations, loading } = this.props;

    return (
      <div>
        { this.state.alert && 
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', position: 'fixed', width: '100%', zIndex: 2001, top: 0, textAlign: 'center'}}>
            <Alert style={{ width: '100%' }} color={this.state.alertStatus} isOpen={this.state.alert} toggle={this.onDismiss}>
              { this.state.alertContents }
            </Alert>
          </div>
        }
        { loading || this.state.loading ?
          <Card className="container" style={{ width: "85%", "textAlign": "left", padding: "40px 45px" }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, color: "#28429e", }}>
              <i className="fas fa-spinner fa-10x fa-pulse"></i>
            </div>
          </Card> 
          : this.state.route === "thankyou" ? 
          // : this.state.route === "form" ? 
          <Thankyou organizations={organizations} state={this.state} setState={this.setState.bind(this)} />
          : this.state.route === "confirm" ? 
          // : this.state.route === "form" ? 
          <Confirm organizations={organizations} state={this.state} setState={this.setState.bind(this)} submitApplication={this.submitApplication.bind(this)} /> 
          : this.state.route === "form" ? 
          <Form organizations={organizations} state={this.state} setState={this.setState.bind(this)} />
          : null
        }

      </div>
    )

  }
};

New.contextTypes = {
  router: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(New));
