import React from 'react';

import Button from '@material-ui/core/Button';

import { BASE_URL } from '../consts';
import Auth from './../modules/Auth';

export default class PolicyButton extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { order, t } = this.props;

    return (

      (order && order.policy) ?
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <Button onClick={ () => window.open(`${BASE_URL}/auth/policies/${order.policy.filename}?token=${Auth.getToken()}`, '_blank') } size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "30px 0px", padding: "5px 100px", "textTransform": "none", "borderRadius": "2px" }} >
              {t['application.submit.download_policy']}
            </Button>              
          </div>  
        </div> : null
          

    );
  }

}
