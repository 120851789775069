import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';

import Dropzone from 'react-dropzone'
import {useDropzone} from 'react-dropzone'


import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import Lang from '../modules/Lang';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    // width: "50%"
  },  
});


class Invoice extends React.Component {

  render(){

    const { organizations, order, match, error, storage } = this.props;
    const { language } = storage;
    const t = Lang[language];    


    if (error == 400){
      return (
        <Card className="container" style={{ width: "90%", "textAlign": "left", padding: "50px 55px", }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, color: "#28429e", flexDirection: 'column' }}>

            <i className="fa fa-ghost" style={{ color: "#28429e", fontSize: 150 }}/>

            <Typography style={{ fontSize: 40, color: "#28429e",  textTransform: "uppercase", paddingBottom: 5, margin: 0, paddingTop: 40 }} gutterBottom >
              {t['invoice.error.not_found']}
            </Typography>     
            <Typography style={{ fontSize: 16, color: "#28429e", paddingBottom: 35, margin: 0 }} gutterBottom >
              {t['invoice.error.invoice']} {match.params.id} {t['invoice.error.is_not_available']}
            </Typography>                        
          </div>
        </Card>
      )
    } else if (error) {
      return (
        <Card className="container" style={{ width: "90%", "textAlign": "left", padding: "50px 55px", }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, color: "#28429e", flexDirection: 'column' }}>

            <i className="fa fa-times" style={{ color: "#28429e", fontSize: 150 }}/>

            <Typography style={{ fontSize: 40, color: "#28429e",  textTransform: "uppercase", paddingBottom: 5, margin: 0, paddingTop: 40 }} gutterBottom >
              {t['invoice.error.unknown']}
            </Typography>    
            <Typography style={{ fontSize: 16, color: "#28429e", paddingBottom: 35, margin: 0 }} gutterBottom >
              {t['invoice.error.contact_support']}
            </Typography>                        
          </div>
        </Card>
      )
    }


    return (
      <Card className="container" style={{ width: "90%", "textAlign": "left", padding: "50px 55px", }}>
        { this.props.loading ? 
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, color: "#28429e", }}>
            <i className="fas fa-spinner fa-10x fa-pulse"></i>
          </div> : 
          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '65%' }}>

                <div style={{  width: '100%', padding: "4px 0", display: 'flex', marginBottom: 0 }}>
                  <Typography style={{ fontSize: 16,  textTransform: "uppercase", fontWeight: 'bold' }} gutterBottom >
                    {t['invoice.title']} {order._id}
                  </Typography>           
                </div>   

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    Project name
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    Lorem ipsum
                  </Typography>              
                </div>   

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    Entrepreneur
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    John Doe
                  </Typography>              
                </div>          

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    Start Date
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    10 Oct 2017
                  </Typography>              
                </div>     

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    End Date
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    10 Oct 2018
                  </Typography>              
                </div>   

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    Type
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    <i className="fas fa-flag" style={{ color: '#28429e'}} /> Entreprenadtid
                  </Typography>              
                </div>   

                <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
                  <Typography style={{ fontSize: 16, width: '50%' }} gutterBottom >
                    Insured amount
                  </Typography>  
                  <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end' }} gutterBottom >
                    10%
                  </Typography>              
                </div>   


              </div>
            </div>
      
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <Button onClick={ () => window.open(order.url, '_blank') } size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "30px 0px", padding: "5px 100px", "textTransform": "none", "borderRadius": "2px" }} >
                  {t['invoice.submit.download_invoice']}
                </Button>              
              </div>  
            </div>   
          </div>   
        }

      </Card> )

    }
};


Invoice.contextTypes = {
  router: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(Invoice));