import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'


const defaultState = {
	language: localStorage.getItem('language') && ['en', 'fi', 'sv'].includes(localStorage.getItem('language')) ? localStorage.getItem('language') : "sv",
};

const storage = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_LANG':
      localStorage.setItem('language', action.language);
      return {
        ...state,
        language: action.language,
      }
    default:
      return state
  }
}


export default (history) => combineReducers({
  router: connectRouter(history),
  storage
})