import React from 'react';

import Typography from '@material-ui/core/Typography';

import { BASE_URL } from '../consts';
import Auth from './../modules/Auth';

import { ThumbIcon } from './';

export default class AssetThumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { handleFn, t, order } = this.props;


    const thumbs = order.assets && order.assets.map(file => {

      return <div style={{
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 60,
        height: 60,
        padding: 4,
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',   
        flexDirection: 'column', 
        position: 'relative',
        zIndex: 1000,
        cursor: 'pointer'
      }} 
        onClick={ () => window.open(`${BASE_URL}/auth/assets/${file.filename}?token=${Auth.getToken()}`, '_blank' ) }
        key={file._id+Math.random()}>

        { file.mimetype.includes("image") ?
          <ThumbIcon icon={"fa-file-image"} />
        : file.mimetype.includes("pdf") ? 
          <ThumbIcon icon={"fa-file-pdf"} />
        : <ThumbIcon icon={"fa-file"} />    
        }      
        
        <div style={{ width: 60, position: 'absolute', bottom: 5, left: 0, right: 0, textAlign: 'center' }}>
          <p style={{ fontSize: 10, margin: 0, overflow: "hidden", "textOverflow": "ellipsis", overflow: 'hidden', lineHeight: 1, }}>{file.originalname}</p>
        </div>

      </div>
    })    


    return (
      <div style={{ width: '100%', padding: "4px 0", marginBottom: 9 }}>
        <Typography style={{ fontSize: 16, width: '100%' }} gutterBottom >
          {t['application.attachments.title']}
        </Typography>  
        <div style={{ fontSize: 16, width: '100%', display: 'flex' }} >

          { thumbs && thumbs.length 
            ? 
            <div>
              {thumbs}
            </div>
            :
            <div>
              <p style={{ fontSize: 12 }}>{t['application.attachments.no_selected.title']}</p>
            </div>          
          } 
        </div>     
        <button onClick={handleFn(true)} style={{ fontSize: 20, margin: "0px", padding: "10px", "textTransform": "none", "borderRadius": "2px", width: 60, height: 60, }} >
          <i className="fa fa-plus" style={{ color: "#28429e", fontSize: 40 }}/>
        </button>                              
      </div>   
    );
  }

}
