import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDom from 'react-dom';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store';


// import { BrowserRouter as Router, Route, Link } from "react-router-dom";


import Base from './components/Base.jsx';
import Auth from './modules/Auth';

import { DashboardPage, SignUpPage, ResetPage, ForgotPage, LoginPage, ProfilePage, ContactPage, ErrorPage, NewPage, InvoicePage, ApplicationPage  } from './containers';

// import './index.css';

const store = configureStore()

Auth.authenticate();

ReactDom.render((
  <MuiThemeProvider muiTheme={getMuiTheme()}>

		<Provider store={store}>
		  <ConnectedRouter history={history}>
		  	<Switch>
			  <Route exact path="/login" component={LoginPage} />
			  <Route exact path="/forgot" component={ForgotPage} />	  	
			  <Route exact path="/reset" component={ResetPage} />	  	
			  <Route render={(props) => 
		    	  <Base {...props}>

		    	  	<Switch>
			    	    <Route exact path="/" render={(props) => {
						  return Auth.isUserAuthenticated() ? (
						    <Redirect to="/account"/>
						  ) : (
						    <Redirect to="/login"/>
						  )
					    }} />
					    <Route exact path="/logout" render={(props) => {
			              Auth.deauthenticateUser();
			              return <Redirect to="/login"/>
			            }} />

			    	    <Route path="/account" render={(props) => {
						  return Auth.isUserAuthenticated() ? (
						  	<Switch>
						    	<Route exact path="/account" component={DashboardPage} />
						    	<Route exact path="/account/applications" component={DashboardPage} />
						    	<Route exact path="/account/application/:id" component={ApplicationPage} />
						    	<Route exact path="/account/invoice/:id" component={InvoicePage} />
						    	<Route exact path="/account/new" component={NewPage} />
						    	<Route exact path="/account/contact" component={ContactPage} />
						    	<Route exact path="/account/profile" component={ProfilePage} />
						    	<Route path="*" component={ErrorPage} />
					    	</Switch>

						  ) : (
						     <Redirect to="/login"/>
						  )
					    }} />

					  	<Route path="*" component={ErrorPage} />
				  	</Switch>
		    	  </Base>
			    	  
			  } />
		    </Switch>
		  </ConnectedRouter>
		</Provider>
	
  </MuiThemeProvider>), document.getElementById('react-app'));

serviceWorker.unregister();








