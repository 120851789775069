import React, {useEffect, useState} from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  // marginTop: 16
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',   
  flexDirection: 'column', 
  position: 'relative',
  zIndex: 1000
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
}

export default function Preview(props) {
  // console.log(props)
  const { t } = props;
  // const [files, setFiles] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    // accept: 'image/*',
    onDrop: acceptedFiles => {

      console.log(acceptedFiles)

      const acceptedFileTypes = [ "png", "jpg", "jpeg", "pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ]

      const mappedFiles = acceptedFiles.filter(f=> acceptedFileTypes.some(type=>{ return f.type.includes(type) } )).map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))

      // setFiles( mappedFiles )
      const unaccepted = acceptedFiles.filter(f=> !acceptedFileTypes.some(type=>{ return f.type.includes(type) }) );

      if (unaccepted.length){
        props.setState({ 
          alert: true,
          alertStatus: "danger",
          alertContents: `Invalid file format selected. Accepted file formats: png, jpg, jpeg, pdf, docx, xlsx.`
        })          
      }




      props.state.files.push(...mappedFiles)
      props.setState({ files: props.state.files })

    }
  })
  
  // console.log(props.state.files)

  const thumbs = props.state.files.map(file => {

    return <div style={thumb} key={file.name+Math.random()}>
      { file.type.includes("image") ?
        <div style={thumbInner}>
          <i className="far fa-file-image fa-4x" style={{ color: '#ddd'}} />
        </div>
      : file.type.includes("pdf") ?
        <div style={thumbInner}>
          <i className="far fa-file-pdf fa-4x" style={{ color: '#ddd'}} />
        </div>
      :
        <div style={thumbInner}>
          <i className="far fa-file fa-4x" style={{ color: '#ddd'}} />
        </div>        
      }      
      <div onClick={ () => { 

        const index = props.state.files.indexOf(file);
        props.state.files.splice(index, 1);
        props.setState({ files: props.state.files }) 

      } } style={{ position: 'absolute', top: 5, right: 5 }}>
        <i className="fas fa-times" /> 
      </div>
      
      <div style={{ width: 90, position: 'absolute', bottom: 5, left: 0, right: 0, textAlign: 'center' }}>
        <p style={{ fontSize: 12, margin: 0, overflow: "hidden", "textOverflow": "ellipsis", overflow: 'hidden', }}>{file.name}</p>
      </div>
    </div>
  })

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    props.state.files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [props.state.files])

  return (
    <section>

      <div {...getRootProps()} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', border: "1px solid #28429e", borderRadius: 5 }}>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 30 }}>
          <input {...getInputProps()} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <i className="far fa-file-pdf fa-4x" style={{ color: '#ddd'}} />
            <p style={{ margin: 0 }}>{t['form.upload.drag.title']}</p>
          </div>
        </div>

      </div>

      <aside style={thumbsContainer}>

        {thumbs.length 
          ? 
          <div>
            <p>{t['form.upload.selected_files.title']}:</p>
            {thumbs}
          </div>
          :
          <div>
            <p>{t['form.upload.no_selected.title']}</p>
          </div>          
        } 
      </aside>      

    </section>
  )
}