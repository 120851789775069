import React from 'react';
import Typography from '@material-ui/core/Typography';

export default class ApplicationOrderRow extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { order, title, value, titleStyles, valueStyles } = this.props;

    return (

      <div style={{ borderBottom: "1px solid #28429e", width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9 }}>
        <Typography style={{ fontSize: 16, width: '50%', ...titleStyles }} gutterBottom >
          { title }
        </Typography>  
        <Typography style={{ fontSize: 16, width: '50%', textAlign: 'end', ...valueStyles }} gutterBottom >
          { value }
        </Typography>              
      </div>

    );
  }

}
