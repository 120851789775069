import React from 'react';
import PropTypes from 'prop-types';
import Auth from '../modules/Auth';

import { generate_xhr } from '../utils';
import { BASE_URL } from '../consts';
import { Invoice } from '../components';

class InvoicePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      order: {}, 
      error: null,
    };
  }

  check_load(){
    return this.state['loading.organizations'] || 
           this.state['loading.order'];
  }

  componentDidMount() {
    
    const h = this.context.router.history;
    generate_xhr(h)(
      'get', 
      `${BASE_URL}/api/v1/organizations`, 
      xhr => e => this.setState({ 'loading.organizations': true  }),
      xhr => e => {
        if (xhr.status === 200) {
          const organizations = JSON.parse(xhr.response)
          this.setState({
            organizations,
          });
        }
      },
      xhr => e => this.setState({ 'loading.organizations': false  }),
    )  

    generate_xhr(h)(
      'get', 
      `${BASE_URL}/api/v1/invoices/view/${this.props.match.params.id}`, 
      xhr => e => this.setState({ 'loading.order': true  }),
      xhr => e => {
        console.log(e)
        if (xhr.status === 200) {
          const order = JSON.parse(xhr.response)
          this.setState({
            order: order,
          });
        } else if (xhr.status){
          this.setState({ error: xhr.status });
        } else {
          this.setState({ error: -1 });
        }
      },
      xhr => e => this.setState({ 'loading.order': false  }),
    )      

  }
  render() {
    const { organizations, order, error } = this.state;

    return (
      <Invoice 
        error={error}
        organizations={organizations} 
        order={order} 
        loading={this.check_load()} 
        {...this.props} 
      />
    );
  }

}

InvoicePage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default InvoicePage;