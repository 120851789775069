import React from 'react';

export default class Loader extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { loading } = this.props;

    return (
      loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, color: "#28429e", }}>
          <i className="fas fa-spinner fa-10x fa-pulse"></i>
        </div> : this.props.children
    );
  }

}
