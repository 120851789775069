import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export default class NavButton extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { test, Icon, onPath, onClickFn, title, buttonProps } = this.props;

    let styles = {
      "padding": "21px 30px",
      "borderRadius": 0,
    }

    if (onPath){
      styles = {...styles, ...{
        backgroundColor: '#eaecf5',
      }}
    }

    return (
      <Button style={styles} onClick={onClickFn} {...buttonProps}>
        <Grid align="center" >
          { typeof Icon == "function" ? <Icon style={{
            width: 60, 
            height: 60, 
            color: "#28429e"
          }} /> : Icon }
          <Typography style={{
            "textTransform": "none", 
            color: "#28429e", 
            marginTop: -10
          }}>
            {title}
          </Typography>
        </Grid>
      </Button>
    );
  }

}
