import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {
    return (

      <div style={{ width: "100%", "textAlign": "left", padding: "0" }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "#28429e", flexDirection: 'column', padding: '4vw 0' }}>

          <i className="fa fa-times" style={{ color: "#28429e", fontSize: '12vw' }}/>

          <Typography style={{ fontSize: '4vw', color: "#28429e",  textTransform: "uppercase", margin: 0, padding: 0 }} gutterBottom >
            404
          </Typography>    
          <Typography style={{ fontSize: '2vw', color: "#28429e", padding: 0, margin: 0 }} gutterBottom >
            The page you’re looking for was not found. 
          </Typography>                        
        </div>
      </div>

    );
  }

}

ErrorPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default ErrorPage;



