import React from 'react';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

export default class ErrorCard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { header, subtitle, icon } = this.props;

    return (

      <Card className="container" style={{ width: "90%", "textAlign": "left", padding: "50px 55px", }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, color: "#28429e", flexDirection: 'column' }}>

          <i className={`fa ${icon}`} style={{ color: "#28429e", fontSize: 150 }}/>

          <Typography style={{ fontSize: 40, color: "#28429e",  textTransform: "uppercase", paddingBottom: 5, margin: 0, paddingTop: 40 }} gutterBottom >
            { header }
          </Typography>     
          <Typography style={{ fontSize: 16, color: "#28429e", paddingBottom: 35, margin: 0 }} gutterBottom >
            { subtitle }
          </Typography>                        
        </div>
      </Card>
          

    );
  }

}
