import React from 'react';
import PropTypes from 'prop-types';
import Auth from '../modules/Auth';

import { BASE_URL } from '../consts';
import { LoginForm } from '../components';

class LoginPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    const storedMessage = localStorage.getItem('successMessage');
    let successMessage = '';

    if (storedMessage) {
      successMessage = storedMessage;
      localStorage.removeItem('successMessage');
    }

    this.state = {
      errors: {},
      successMessage,
      user: {
        email: '',
        password: ''
      }
    };

    this.processForm = this.processForm.bind(this);
    this.changeUser = this.changeUser.bind(this);
  }

  processForm(event) {
    event.preventDefault();

    this.setState({
      errors: {},
      loading: true
    })

    const email = encodeURIComponent(this.state.user.email);
    const password = encodeURIComponent(this.state.user.password);
    const formData = `email=${email}&password=${password}`;

    const xhr = new XMLHttpRequest();
    xhr.open('post', `${BASE_URL}/api/v1/login`);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.responseType = 'json';
    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {

        const res = typeof xhr.response === "string" ? JSON.parse(xhr.response) : xhr.response;
        Auth.authenticateUser( res.token, JSON.stringify(res.user) );

        this.context.router.history.push('/')
      } else {
        const errors = {};

        if (xhr.response.err && xhr.response.err === "Unauthorized" ){
          errors.summary = "Access denied. Please try again.";
        } else if (xhr.response[0] && xhr.response[0].param === "email"){
          errors.summary = "Email is invalid. Please try again.";
        } else {
          errors.summary = "Unknown error has occured. Please contact customer support.";
        }

        this.setState({ errors });
      }
    });
    xhr.addEventListener('loadend', this.setState({ loading: false }));    
    xhr.send(formData);
  }

  changeUser(event) {
    const field = event.target.name;
    const user = this.state.user;
    user[field] = event.target.value;

    this.setState({
      user
    });
  }

  render() {
    return (
      <LoginForm
        successMessage={this.state.successMessage}
        onSubmit={this.processForm}
        onChange={this.changeUser}
        errors={this.state.errors}
        user={this.state.user}
        loading={this.state.loading}
      />
    );
  }

}


LoginPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default LoginPage;
