import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({

});


class MTextField extends React.Component {
  state = {
    dirty: false,
    blur: false, 
    input: null
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  generateInput = () => {

  }

  render(){
    const { dirty, blur } = this.state;
  	const { label, placeholder, onChangeFn, value, errorMsg, required, type, pattern, typeMismatchError, stepMismatchError, patternMismatchError, noValueError, multiple, addBtnTitle, removeBtnTitle, addFn, removeFn, tag, submit, defaultValue, step, min, max, consolel } = this.props;

    const $errorMsg = [];
    let error = false;

    const valid = this.input && this.input.validity.valid;
    consolel && console.log(this.input && this.input.validity)

    if (!value || (typeof value === "object" && value !== null && value[0] === "") ){
      $errorMsg.push(`${noValueError}\n`);
    }
    if (type === "email" && this.input && this.input.validity.typeMismatch){
      $errorMsg.push(`${typeMismatchError}\n`);
    }
    if (this.input && this.input.validity.patternMismatch){
      $errorMsg.push(`${patternMismatchError}\n`);
    }  
    if (this.input && this.input.validity.stepMismatch){
      $errorMsg.push(`${stepMismatchError}\n`);
    }        

    // console.log( value, valid )

    // return null;

  	return (
      <div style={{ padding: "0 8px", margin: 0 }}>
        <TextField
          value={typeof value === "object" && value !== null ? value[0] : value}
          defaultValue={defaultValue}
          inputRef={ i => this.input = i }
          inputProps={{ pattern: pattern, step, min, max,  }}
          required={required}
          type={type || "text"}
          error={(submit || (dirty && blur)) && (!valid)}
          label={label}
          style={{ margin: "8px 0" }}
          placeholder={placeholder}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          format="yyyy/MM/dd"
          onChange={ value => { onChangeFn(value, 0) } }
          onBlur={ () => this.setState({ dirty: true, blur: true }) }
          helperText={ (submit || (dirty && blur)) && !valid && ($errorMsg) }
        /> 

        { multiple && 

          <div>
            <Button onClick={addFn} size="large" variant="contained" color="primary" style={{ fontSize: 14, padding: "5px 20px", margin: "10px 0px", "textTransform": "none", "borderRadius": "2px" }} >
                    {addBtnTitle}
              </Button>
          {  Array(value.length - 1 || 0).fill().map( (_, k) =>
            { 
              return <div style={{ display: 'flex' }} key={tag+k}>
                <div style={{ width: "90%" }}>
                  <TextField
                    // inputRef={ i => this.input = i }
                    pattern={pattern}
                    // required={required}
                    type={type || "text"}
                    // error={dirty && blur && (!valid)}
                    label={label}
                    style={{ margin: "8px 0" }}
                    placeholder={placeholder}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={ value => { onChangeFn(value, k+1) } }
                    // onBlur={ () => this.setState({ dirty: true, blur: true }) }
                    // helperText={ dirty && blur && !valid && ($errorMsg) }
                    value={value[k+1]}
                  /> 
                </div>
                <div style={{ width: "10%" }}>
                  <Button onClick={removeFn(k+1)} size="large" variant="contained" color="primary" style={{ fontSize: 14, padding: "5px 20px", margin: "10px 10px", "textTransform": "none", "borderRadius": "2px" }} >
                        {removeBtnTitle}
                  </Button>
                </div>
              </div> }
            )}
          </div>
        }
        
      </div> );

  }
};

MTextField.contextTypes = {
  router: PropTypes.object.isRequired
};

export default withStyles(styles)(MTextField);
