import React from 'react';

import { Alert } from 'reactstrap';

export default class AlertWrapper extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { alert, status, contents, dismissFn } = this.props;

    return (
      alert ? 
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', position: 'fixed', width: '100%', zIndex: 2001, top: 0, textAlign: 'center'}}>
          <Alert style={{ width: '100%' }} color={status} isOpen={alert} toggle={dismissFn}>
            { contents }
          </Alert>
        </div> : null
    );
  }

}
