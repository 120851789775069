
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ViewList, AddCircleOutline, Mail, Flag, AccountCircle, Settings } from '@material-ui/icons';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Auth from './../modules/Auth';
import Lang from '../modules/Lang';

import { getLanguageHeader, getLanguageIcon } from '../utils';
import { NavButton } from './../elements';

const styles = theme => ({
  active: {
    backgroundColor: '#eaecf5',
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
});


class Base extends React.Component {
  state = {
    languageEl: null,
    accountEl: null
  };

  handleClick = (item) => event => {
    this.setState({ [item]: event.currentTarget });
  };
  handleSelectLanguage = (item, language) => () => {
    this.props.setLanguage(language);
    this.setState({ [item]: null });
  };  
  handleSelect = (item) => () => {
    this.setState({ [item]: null });
  };    

  render() {

    const h = this.context.router.history;

    const { classes, children, storage } = this.props;
    const { languageEl, accountEl } = this.state;

    const { language } = storage;
    const t = Lang[language];

    const { pathname } = window.location;

    const languageHeader = getLanguageHeader(language);
    const languageIcon = getLanguageIcon(language);

    return (
      <React.Fragment>
        <CssBaseline />
        { Auth.isUserAuthenticated() &&
        <AppBar position="static" color="default" style={{ backgroundColor: "white", position: 'relative', }}>
          <Toolbar>
            <span onClick={()=>h.push('/')} style={{ paddingLeft: 75, flex: 1, }}>
              <img src={'/img/RSG-Nordics-Logo-SVG.svg'} style={{"height": 60 }}/>
            </span>

            <NavButton 
              Icon={ViewList}
              onPath={["/account/applications", "/account"].includes(pathname)} 
              onClickFn={()=>h.push('/account/applications')}
              title={t['nav.applications.all']}
            />
            <NavButton 
              Icon={AddCircleOutline}
              onPath={pathname === "/account/new"} 
              onClickFn={()=>h.push('/account/new')}
              title={t['nav.applications.new']}
            />            
            <NavButton 
              Icon={Mail}
              onPath={pathname === "/account/contact"} 
              onClickFn={()=>h.push('/account/contact')}
              title={t['nav.contact']}
            />  

            <NavButton 
              Icon={<img src={`/img/${languageIcon}`} height="50" width="50" style={{ margin: 5}}/>}
              onClickFn={this.handleClick('languageEl')}
              title={languageHeader}
              buttonProps={{
                'aria-owns': languageEl ? 'simple-menu' : undefined,
                'aria-haspopup':"true"
              }}
            />              

            <Menu
              id="simple-menu"
              anchorEl={languageEl}
              open={Boolean(languageEl)}
              onClose={this.handleSelect('languageEl')}
            >
              <MenuItem onClick={this.handleSelectLanguage('languageEl', "sv")}>
                <img src="/img/sweden.png" height="35" width="35"/>
                <p style={{ paddingLeft: 10, margin: 0 }}>Svenska</p>
              </MenuItem>
              <MenuItem onClick={this.handleSelectLanguage('languageEl', "en")}>
                <img src="/img/united-kingdom.png" height="35" width="35"/>
                <p style={{ paddingLeft: 10, margin: 0 }}>English</p>
              </MenuItem>
              {/* <MenuItem onClick={this.handleSelectLanguage('languageEl', "fi")}>
                <img src="/img/finland.png" height="35" width="35"/>
                <p style={{ paddingLeft: 10, margin: 0 }}>Suomi</p>
              </MenuItem> */}              
            </Menu>

            <Button className={`${pathname === "/account/profile" && classes.active}`} style={{ marginRight: 50, "padding": "21px 30px", "borderRadius": 0, }}
              aria-owns={accountEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick('accountEl')}
            >
              <Grid align="center" >
                <AccountCircle style={{
                  width: 60, 
                  height: 60, 
                  color: "#28429e"
                }} />
                <Typography style={{
                  "textTransform": "none", 
                  color: "#28429e", 
                  marginTop: -10
                }}>
                  {t['nav.profile']}
                </Typography>
              </Grid>
            </Button>   

            <Menu
              id="simple-menu"
              anchorEl={accountEl}
              open={Boolean(accountEl)}
              onClose={this.handleSelect('accountEl')}
            >
              <MenuItem align="center" style={{ }} onClick={() => { this.handleClick('accountEl'); } }>
                <ListItemIcon>
                  <Settings style={{ color: "#28429e" }} />
                </ListItemIcon>
                <span style={{ "color": "#28429e", "fontWeight": "400", "textDecoration": "none" }} onClick={()=>h.push('/account/profile')} >{t['nav.profile.settings']}</span>
              </MenuItem>
              <MenuItem onClick={() => { this.handleClick('accountEl'); } }>
                <Grid align="center" >

                  <Button size="large" variant="contained" color="primary" style={{ fontSize: 20, "borderRadius": "2px", "textTransform": "none", padding: "0px 50px" }} onClick={ () => h.push('/logout') } >
                      {t['nav.logout']}
                  </Button>

                </Grid>
              </MenuItem>
            </Menu>

          </Toolbar>
        </AppBar> }

        <main style={{
          backgroundColor: '#eaecf5',
          height: "100%",
          width: "100%",
          padding: "50px 0"
        }}>
          {children}
        </main>

      </React.Fragment>
    );

  }
}

Base.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    storage: state.storage
  };
};
const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch({ type: 'SET_LANG', language: language }),
});

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(Base));