import React from 'react';
import PropTypes from 'prop-types';
import Auth from '../modules/Auth';

import { generate_xhr } from '../utils';
import { BASE_URL } from '../consts';
import { New } from '../components';

class NewPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: []
    };
  }

  check_load(){
    return this.state['loading.organizations']
  }

  componentDidMount() {

    // const h = this.context.router.history;
    // generate_xhr(h)(
    //   'get', 
    //   `${BASE_URL}/api/v1/organizations`, 
    //   xhr => e => this.setState({ 'loading.organizations': true  }),
    //   xhr => e => {
    //     if (xhr.status === 200) {
    //       // console.log(JSON.parse(xhr.response))
    //       const organizations = JSON.parse(xhr.response)
    //       this.setState({
    //         organizations,
    //       });
    //     }
    //   },
    //   xhr => e => this.setState({ 'loading.organizations': false  }),
    // )  

  }
  render() {
    const { organizations } = this.state;
    return (<New organizations={organizations} loading={this.check_load()} />);
  }

}

NewPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default NewPage;



