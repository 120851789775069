import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { unflatten } from 'flat';

import moment from 'moment';

import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/sv";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { KeyboardDatePicker } from "@material-ui/pickers";


import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import { Preview, MTextField } from "../elements";

import Lang from '../modules/Lang';
import Auth from '../modules/Auth';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    // width: "50%"
  },  
});


class Form extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDate = name => date => {
    this.props.setState({ [name]: date });
    // console.log(date);
  }

  handleChange = name => event => {
    this.props.setState({ [name]: event.target.value });
  }

  getApplicantValue = (orgs, o, org_tag, state_tag) =>{
    const { state } = this.props;
    const find = orgs.find(org=>org[org_tag] ===  state[state_tag])
    const val = o && o[org_tag];

    // console.log(orgs)

    return (val && !find) 
        ? state[state_tag] 
        : (val) || ""
  }

  handleOrganizationChange = event => {

    const { state } = this.props;

    const user = Auth.getUser();
    const o = user.organization && user.organization.find(i=>i._id === event.target.value);
    const orgs = user.organization;

    const change = {
      organization: event.target.value,
      "applicant.name": this.getApplicantValue(orgs, o, "name", "applicant.name"),
      "applicant.organization": this.getApplicantValue(orgs, o, "business_id", "applicant.organization"),
      "applicant.address": this.getApplicantValue(orgs, o, "street_address", "applicant.address"),
      "applicant.zip": this.getApplicantValue(orgs, o, "zip", "applicant.zip"),
      "applicant.city": this.getApplicantValue(orgs, o, "city", "applicant.city"), 
    }

    // console.log("state", change)

    this.props.setState(change);
  }  

  handleMultipleChange = name => (event, index) => {
    if (typeof this.props.state[name] !== "object" ){ this.props.state[name] = []; }
    this.props.state[name][index] = event.target.value;

    this.props.setState({ [name]: this.props.state[name] });
  }  

  handleCheck = name => value => event => {
    this.props.setState({ [name]: value === this.props.state[name] ? null : value });
  };  

  render(){
    const h = this.context.router.history;
    const { classes, state, setState, organizations, storage } = this.props;
    const { language } = storage;

    const user = Auth.getUser();

    // const language = localStorage.getItem('language') || "en";
    const t = Lang[language];

    // console.log( `state["organization"]`, state["organization"] )

    return (
      <Card className="container" style={{ width: "85%", "textAlign": "left", padding: "40px 45px" }}>

        <form noValidate ref={form => this.formEl = form} method="POST" onSubmit={
          (e) => {
            e.preventDefault();

            state.submit = true;

            if (state["guarantee.while_contract"]){
              if (state["guarantee.while_contract_amount_percent"] === "Other") 
                state["guarantee.while_contract_amount_percent"] = state["guarantee.while_contract_amount_percent_other"];

              state["guarantee.while_contract_start_date"] = moment(state["contract.start_date"]).format("YYYY-MM-DD");
              state["guarantee.while_contract_end_date"] = moment(state["contract.end_date"]).format("YYYY-MM-DD");            
            }

            if (state["guarantee.while_warranty"]){
              if (state["guarantee.while_warranty_amount_percent"] === "Other") 
                state["guarantee.while_warranty_amount_percent"] = state["guarantee.while_warranty_amount_percent_other"];     
              if (state["guarantee.while_warranty_period"] === "Other") 
                state["guarantee.while_warranty_period"] = state["guarantee.while_warranty_period_other"];

              state["guarantee.while_warranty_start_date"] = moment(state["contract.end_date"]).format("YYYY-MM-DD");
              state["guarantee.while_warranty_end_date"] = moment(state["guarantee.while_warranty_start_date"]).add(state["guarantee.while_warranty_period"], 'years').format("YYYY-MM-DD");                 
            }

            state["contract.agreement_date"] = moment(state["contract.agreement_date"]).format("YYYY-MM-DD");
            state["contract.start_date"] = moment(state["contract.start_date"]).format("YYYY-MM-DD");
            state["contract.end_date"] = moment(state["contract.end_date"]).format("YYYY-MM-DD");


            if (!state["guarantee.while_contract"] && !state["guarantee.while_warranty"]){
              state.alert = true;
              state.alertStatus = "danger";
              state.alertContents = "Please select at least one product";
            } else if ( 
              (state["guarantee.while_contract"] && !state["guarantee.while_contract_amount_percent"]) 
              || (state["guarantee.while_warranty"] && (
                !state["guarantee.while_warranty_amount_percent"] || 
                !state["guarantee.while_warranty_period"]) ) 
            ) {
              state.alert = true;
              state.alertStatus = "danger";
              state.alertContents = "Please select valid product contract values and insurance period";
            } else if (this.formEl.checkValidity()){
              state.route = "confirm";
            } else {
              state.alert = true;
              state.alertStatus = "danger";
              state.alertContents = t['new.application.message.validation_error'];
            }            

            // console.log(state)

            setState(state)

          }} >


          { user.role.name === "broker" && 
            <div>
              <Typography style={{ fontSize: 30, color: "#28429e", textAlign: 'center', textTransform: "uppercase", marginBottom: 10 }} gutterBottom >
                {t['form.broker.title']}
              </Typography> 

              <FormControl required className={classes.formControl} style={{ padding: "0 0px", margin: "0 8px", display: 'flex', flexDirection: 'column', marginBottom: 60 }}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  {t['form.broker.organization.title']}
                </InputLabel>
                <NativeSelect
                  value={state["organization"]}
                  onChange={this.handleOrganizationChange}
                  input={<Input required id="age-native-label-placeholder" />}
                >
                  {user.organization.map(o=>{
                    return <option key={o._id} value={o._id}>{o.name}</option>
                  })}
                </NativeSelect>
              </FormControl>
            </div>
          }

          <Typography style={{ fontSize: 30, color: "#28429e", textAlign: 'center', textTransform: "uppercase", marginBottom: 40 }} gutterBottom >
            {t['form.applicant.title']}
          </Typography> 

          <Typography style={{ color: "#28429e", fontSize: 18, border: "2px solid #28429e", padding: "14px 25px", borderRadius: 4, margin: 10, marginBottom: 30, textAlign: 'center' }}>{t['form.applicant.subtitle1']} <b>{t['form.applicant.subtitle2']}</b> {t['form.applicant.subtitle3']}
          </Typography>
            
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["applicant.name"]}
            label={t['form.applicant.name.title']}
            onChangeFn={this.handleChange('applicant.name')}
          />

          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["applicant.organization"]}
            label={t['form.applicant.organization.title']}
            onChangeFn={this.handleChange('applicant.organization')}
            pattern={"[0-9]{6}-[0-9]{4}"}
            patternMismatchError={'Organization number pattern is invalid'}
            
          />

          {/*
          <FormControl required className={classes.formControl} style={{ padding: "0 0px", margin: "0 8px", display: 'flex', flexDirection: 'column' }}>
            <InputLabel shrink htmlFor="age-native-label-placeholder">
              {t['form.applicant.organization.title']}
            </InputLabel>
            <NativeSelect
              value={state["applicant.organization"]}
              onChange={this.handleChange('applicant.organization')}
              input={<Input required id="age-native-label-placeholder" />}
            >
              {organizations.map(o=>{
                return <option key={o._id} value={o._id}>{o.name}</option>
              })}
            </NativeSelect>
          </FormControl>
          */}

          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["applicant.invoice_declaration"]}
            label={t['form.applicant.invoice_declaration.title']}
            onChangeFn={this.handleChange('applicant.invoice_declaration')}
          />  
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["applicant.invoice_address"]}
            label={t['form.applicant.invoice_address.title']}
            onChangeFn={this.handleChange('applicant.invoice_address')}
          />            

          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            value={state["applicant.address"]}
            label={t['form.applicant.address.title']}
            onChangeFn={this.handleChange('applicant.address')}
          />                
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["applicant.zip"]}
            label={t['form.applicant.zip.title']}
            onChangeFn={this.handleChange('applicant.zip')}
          />          
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["applicant.city"]}
            label={t['form.applicant.city.title']}
            onChangeFn={this.handleChange('applicant.city')}
          />
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            tag={"applicant.contact"}
            multiple
            multipleCount={state["applicant.contactCount"]}
            addBtnTitle={t['form.applicant.contact.add']}
            removeBtnTitle={t['form.applicant.contact.remove']}
            addFn={() => { 
              state["applicant.contact"].push("");
              setState({ "applicant.contact": state["applicant.contact"] }) 
            }}
            removeFn={
              (index) => (e) => {
                state["applicant.contact"].splice(index, 1);
                return setState({ "applicant.contact": state["applicant.contact"] }) 
              }
            }            
            required
            value={state["applicant.contact"]}
            label={t['form.applicant.contact.title']}
            onChangeFn={this.handleMultipleChange('applicant.contact')}
          />
          {/*<MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["applicant.phone_vaxel"]}
            label={t['form.applicant.phone_vaxel.title']}
            onChangeFn={this.handleChange('applicant.phone_vaxel')}
          />
          */}
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["applicant.phone"]}
            label={t['form.applicant.phone.title']}
            onChangeFn={this.handleChange('applicant.phone')}
          />
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            type="email"
            value={state["applicant.email"]}
            label={t['form.applicant.email.title']}
            onChangeFn={this.handleChange('applicant.email')}
            typeMismatchError={t['form.applicant.email.typeMismatchError']}
          />

          <hr style={{ borderTop: "1px solid #28429e", marginTop: 30 }} />

          <Typography style={{ fontSize: 30, color: "#28429e", textAlign: 'center', textTransform: "uppercase", margin: "40px 0px" }} gutterBottom >
              {t['form.contractor.title']}
          </Typography> 
            


          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contractor.name"]}
            label={t['form.contractor.name.title']}
            onChangeFn={this.handleChange('contractor.name')}
          />

          {/*
          <FormControl required className={classes.formControl} style={{ padding: "0 0px", margin: "0 8px", display: 'flex', flexDirection: 'column' }}>
            <InputLabel shrink htmlFor="age-native-label-placeholder">
              {t['form.contractor.organization.title']}
            </InputLabel>
            <NativeSelect
              value={state["contractor.organization"]}
              onChange={this.handleChange('contractor.organization')}
              input={<Input required id="age-native-label-placeholder" />}
            >
              {organizations.map(o=>{
                return <option key={o._id} value={o._id}>{o.name}</option>
              })}
            </NativeSelect>
          </FormControl>
          */}

          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contractor.organization"]}
            label={t['form.contractor.organization.title']}
            onChangeFn={this.handleChange('contractor.organization')}
            pattern={"[0-9]{6}-[0-9]{4}"}
            patternMismatchError={'Organization number pattern is invalid'}
            
          />

          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contractor.address"]}
            label={t['form.contractor.address.title']}
            onChangeFn={this.handleChange('contractor.address')}
          />                
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contractor.zip"]}
            label={t['form.contractor.zip.title']}
            onChangeFn={this.handleChange('contractor.zip')}
          />          
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contractor.city"]}
            label={t['form.contractor.city.title']}
            onChangeFn={this.handleChange('contractor.city')}
          />
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            tag={"contractor.contact"}
            multiple
            multipleCount={state["contractor.contactCount"]}
            addBtnTitle={t['form.contractor.contact.add']}
            removeBtnTitle={t['form.contractor.contact.remove']}
            addFn={() => { 
              state["contractor.contact"].push("");
              setState({ "contractor.contact": state["contractor.contact"]  }) 
            }}
            removeFn={
              (index) => (e) => {
                state["contractor.contact"].splice(index, 1);
                // console.log(state["contractor.contact"])
                return setState({ "contractor.contact": state["contractor.contact"] }) 
              }
            }            
            required
            value={state["contractor.contact"]}
            label={t['form.contractor.contact.title']}
            onChangeFn={this.handleMultipleChange('contractor.contact')}
          />
          {/*<MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contractor.phone_vaxel"]}
            label={t['form.contractor.phone_vaxel.title']}
            onChangeFn={this.handleChange('contractor.phone_vaxel')}
          />
          */}
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contractor.phone"]}
            label={t['form.contractor.phone.title']}
            onChangeFn={this.handleChange('contractor.phone')}
          />
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            type="email"
            value={state["contractor.email"]}
            label={t['form.contractor.email.title']}
            onChangeFn={this.handleChange('contractor.email')}
            typeMismatchError={t['form.contractor.email.typeMismatchError']}
          />              

          <hr style={{ borderTop: "1px solid #28429e", marginTop: 30 }} />





          <FormControlLabel
            control={
              <Checkbox
                checked={state['subcontract']}
                onChange={ () => this.props.setState({ subcontract: !state['subcontract'] })}
                color="primary"
              />    
            }
            label={t['form.subcontract.checkbox.label']}
          />    
      


          { state['subcontract'] 
            ? <div>
              <Typography style={{ fontSize: 30, color: "#28429e", textAlign: 'center', textTransform: "uppercase", margin: "40px 0px" }} gutterBottom >
                  {t['form.subcontractor.title']}
              </Typography> 
                
              <MTextField
                submit={state.submit}
                placeholder={t['form.input.placeholder']}
                noValueError={t['form.input.required.noValueError']}
                required
                value={state["subcontractor.name"]}
                label={t['form.subcontractor.name.title']}
                onChangeFn={this.handleChange('subcontractor.name')}
              />
              <MTextField
                submit={state.submit}
                placeholder={t['form.input.placeholder']}
                noValueError={t['form.input.required.noValueError']}
                required
                value={state["subcontractor.organization"]}
                label={t['form.subcontractor.organization.title']}
                onChangeFn={this.handleChange('subcontractor.organization')}
                pattern={"[0-9]{6}-[0-9]{4}"}
                patternMismatchError={'Organization number pattern is invalid'}
                
              />

              <MTextField
                submit={state.submit}
                placeholder={t['form.input.placeholder']}
                noValueError={t['form.input.required.noValueError']}
                required
                value={state["subcontractor.address"]}
                label={t['form.subcontractor.address.title']}
                onChangeFn={this.handleChange('subcontractor.address')}
              />                
              <MTextField
                submit={state.submit}
                placeholder={t['form.input.placeholder']}
                noValueError={t['form.input.required.noValueError']}
                required
                value={state["subcontractor.zip"]}
                label={t['form.subcontractor.zip.title']}
                onChangeFn={this.handleChange('subcontractor.zip')}
              />          
              <MTextField
                submit={state.submit}
                placeholder={t['form.input.placeholder']}
                noValueError={t['form.input.required.noValueError']}
                required
                value={state["subcontractor.city"]}
                label={t['form.subcontractor.city.title']}
                onChangeFn={this.handleChange('subcontractor.city')}
              />
              <MTextField
                submit={state.submit}
                placeholder={t['form.input.placeholder']}
                noValueError={t['form.input.required.noValueError']}
                tag={"subcontractor.contact"}
                multiple
                multipleCount={state["subcontractor.contactCount"]}
                addBtnTitle={t['form.subcontractor.contact.add']}
                removeBtnTitle={t['form.subcontractor.contact.remove']}
                addFn={() => { 
                  state["subcontractor.contact"].push("");
                  setState({ "subcontractor.contact": state["subcontractor.contact"]  }) 
                }}
                removeFn={
                  (index) => (e) => {
                    state["subcontractor.contact"].splice(index, 1);
                    // console.log(state["subcontractor.contact"])
                    return setState({ "subcontractor.contact": state["subcontractor.contact"] }) 
                  }
                }            
                required
                value={state["subcontractor.contact"]}
                label={t['form.subcontractor.contact.title']}
                onChangeFn={this.handleMultipleChange('subcontractor.contact')}
              />
              <MTextField
                submit={state.submit}
                placeholder={t['form.input.placeholder']}
                noValueError={t['form.input.required.noValueError']}
                required
                value={state["subcontractor.phone"]}
                label={t['form.subcontractor.phone.title']}
                onChangeFn={this.handleChange('subcontractor.phone')}
              />
              <MTextField
                submit={state.submit}
                placeholder={t['form.input.placeholder']}
                noValueError={t['form.input.required.noValueError']}
                required
                type="email"
                value={state["subcontractor.email"]}
                label={t['form.subcontractor.email.title']}
                onChangeFn={this.handleChange('subcontractor.email')}
                typeMismatchError={t['form.subcontractor.email.typeMismatchError']}
              />              

              <hr style={{ borderTop: "1px solid #28429e", marginTop: 30 }} />
            </div> 
            : null }











          <Typography style={{ fontSize: 30, color: "#28429e", textAlign: 'center', textTransform: "uppercase", margin: "40px 0px" }} gutterBottom >
                {t['form.contract.title']}
          </Typography> 
        
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contract.building"]}
            label={t['form.contract.building.title']}
            onChangeFn={this.handleChange('contract.building')}
          />
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contract.work_area"]}
            label={t['form.contract.work_area.title']}
            onChangeFn={this.handleChange('contract.work_area')}
          />   
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contract.project_number"]}
            label={t['form.contract.project_number.title']}
            onChangeFn={this.handleChange('contract.project_number')}
          />   
          {/*
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            type={"date"}
            value={state["contract.agreement_date"]}
            label={t['form.contract.agreement_date.title']}
            onChangeFn={this.handleChange('contract.agreement_date')}
          />   
          */}     
          <div style={{ padding: "0 8px", margin: 0 }}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>  
              <KeyboardDatePicker
                // clearable
                style={{ width: '100%' }}
                label={t['form.contract.agreement_date.title']}
                value={state["contract.agreement_date"]}
                onChange={this.handleDate('contract.agreement_date')}
                minDate={moment().subtract(1, 'year')}
                format="YYYY-MM-DD"
              />       
            </MuiPickersUtilsProvider>  
          </div>   
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            type={"number"}
            required
            value={state["contract.sum"]}
            label={t['form.contract.sum.title']}
            step={"1"}
            min={0}
            onChangeFn={this.handleChange('contract.sum')}
            stepMismatchError={"Decimal values are invalid!"}
          />        
          <MTextField
            submit={state.submit}
            placeholder={t['form.input.placeholder']}
            noValueError={t['form.input.required.noValueError']}
            required
            value={state["contract.concerns"]}
            label={t['form.contract.concerns.title']}
            onChangeFn={this.handleChange('contract.concerns')}
          />    

          <div style={{ padding: "0 8px", margin: 0 }}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>  
              <KeyboardDatePicker
                // clearable
                style={{ width: '100%' }}
                label={t['form.contract.start_date.title']}
                value={state["contract.start_date"]}
                onChange={this.handleDate('contract.start_date')}
                minDate={moment(state['contract.start_date']).subtract(1, 'year')}
                format="YYYY-MM-DD"
              />         
            </MuiPickersUtilsProvider>
          </div>   

          <div style={{ padding: "8px 8px 0 8px", margin: 0 }}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>          
              <KeyboardDatePicker
                // clearable
                style={{ width: '100%' }}
                label={t['form.contract.end_date.title']}
                value={state["contract.end_date"]}
                onChange={this.handleDate('contract.end_date')}
                minDate={moment(state["contract.start_date"]).add(1, 'day')}
                // maxDate={moment(state['contract.start_date']).add(5, 'years')}
                format="YYYY-MM-DD"
              />         
            </MuiPickersUtilsProvider>
          </div>                                              

          <hr style={{ borderTop: "1px solid #28429e", marginTop: 30 }} />

          <Typography style={{ fontSize: 30, color: "#28429e", textAlign: 'center', textTransform: "uppercase", margin: "40px 0px" }} gutterBottom >
                {t['form.guarantee.title']}
          </Typography> 


          <div style={{ margin: "20px 0", padding: 15, paddingBottom: 0, border: "1px solid #28429e", borderRadius: 5 }}>
            <FormControl component="fieldset" className={classes.formControl} style={{width: '50%'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state['guarantee.while_contract']}
                    onChange={this.handleCheck('guarantee.while_contract')(true)}
                    color="primary"
                  />
                }
                label={t['form.guarantee.while_contract.title']}
              />    
            </FormControl>        
            <FormControl disabled={!state['guarantee.while_contract']} component="fieldset" className={classes.formControl} style={{width: '50%'}}>
              <FormLabel component="legend">{t['form.guarantee.while_contract_amount_percent.title']}</FormLabel>        
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state['guarantee.while_contract_amount_percent'] === "10"}
                      onChange={this.handleCheck('guarantee.while_contract_amount_percent')("10")}
                      color="primary"
                    />
                  }
                  label="10%"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state['guarantee.while_contract_amount_percent'] === "5"}
                      onChange={this.handleCheck('guarantee.while_contract_amount_percent')("5")}
                      color="primary"
                    />
                  }
                  label="5%"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state['guarantee.while_contract_amount_percent'] === "Other"}
                      onChange={this.handleCheck('guarantee.while_contract_amount_percent')("Other")}
                      color="primary"
                    />
                  }
                  label={<span>{t['form.guarantee.while_contract_amount_percent.other']} <TextField 
                    type={"number"}
                    inputProps={{ step: "1", min: 1, max: 100, style: { width: 40, padding: 0 }, max: 100, min: 1 }}
                    onClick={ () => {
                      setState({'guarantee.while_contract_amount_percent': 'Other'})
                    }}
                    onChange={this.handleChange('guarantee.while_contract_amount_percent_other')}
                    onBlur={ () => {
                      if (!state['guarantee.while_contract_amount_percent_other']){
                        setState({'guarantee.while_contract_amount_percent': null})
                      }
                    }}
                  /> %</span>}
                />

              </div>              
            </FormControl>                
          </div>

          <div style={{ margin: "20px 0", padding: 15, paddingBottom: 0, border: "1px solid #28429e", borderRadius: 5 }}>
            <FormControl component="fieldset" className={classes.formControl} style={{width: '50%'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state['guarantee.while_warranty']}
                    onChange={this.handleCheck('guarantee.while_warranty')(true)}
                    color="primary"
                  />
                }
                label={t['form.guarantee.while_warranty.title']}
              />
            </FormControl>        
            <FormControl disabled={!state['guarantee.while_warranty']} component="fieldset" className={classes.formControl} style={{width: '50%'}}>
              <FormLabel component="legend">{t['form.guarantee.while_warranty_amount_percent.title']}</FormLabel>        
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state['guarantee.while_warranty_amount_percent'] === "10"}
                      onChange={this.handleCheck('guarantee.while_warranty_amount_percent')("10")}
                      color="primary"
                    />
                  }
                  label="10%"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state['guarantee.while_warranty_amount_percent'] === "5"}
                      onChange={this.handleCheck('guarantee.while_warranty_amount_percent')("5")}
                      color="primary"
                    />
                  }
                  label="5%"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state['guarantee.while_warranty_amount_percent'] === "Other"}
                      onChange={this.handleCheck('guarantee.while_warranty_amount_percent')("Other")}
                      color="primary"
                    />
                  }
                  label={<span>{t['form.guarantee.while_warranty_amount_percent.other']} <TextField 
                    type={"number"}
                    inputProps={{ step: "1", min: 1, max: 100, style: { width: 40, padding: 0 }, max: 100, min: 1 }}
                    onClick={ () => {
                      setState({'guarantee.while_warranty_amount_percent': 'Other'})
                    }}
                    onChange={this.handleChange('guarantee.while_warranty_amount_percent_other')}
                    onBlur={ () => {
                      if (!state['guarantee.while_warranty_amount_percent_other']){
                        setState({'guarantee.while_warranty_amount_percent': null})
                      }
                    }}

                  /> %</span>}
                />
              </div>             

              <FormLabel component="legend">{t['form.guarantee.while_warranty_period.title']}</FormLabel>        
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state['guarantee.while_warranty_period'] === "2"}
                      onChange={this.handleCheck('guarantee.while_warranty_period')("2")}
                      color="primary"
                    />
                  }
                  label={t['form.guarantee.while_warranty_period.2']}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state['guarantee.while_warranty_period'] === "5"}
                      onChange={this.handleCheck('guarantee.while_warranty_period')("5")}
                      color="primary"
                    />
                  }
                  label={t['form.guarantee.while_warranty_period.5']}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state['guarantee.while_warranty_period'] === "Other"}
                      onChange={this.handleCheck('guarantee.while_warranty_period')("Other")}
                      color="primary"
                    />
                  }
                  label={<span>{t['form.guarantee.while_warranty_period.other']} <TextField 
                    type={"number"}
                    inputProps={{ step: "1", min: 1, max: 20, style: { width: 40, padding: 0 }, max: 100, min: 1 }}
                    onClick={ () => {
                      setState({'guarantee.while_warranty_period': 'Other'})
                    }}
                    onChange={this.handleChange('guarantee.while_warranty_period_other')}
                    onBlur={ () => {
                      if (!state['guarantee.while_warranty_period_other']){
                        setState({'guarantee.while_warranty_period': null})
                      }
                    }}

                  /> {t['form.guarantee.while_warranty_period.year']}</span>}
                />       
              </div>        

            </FormControl>                
          </div>


          <Typography style={{ fontSize: 30, color: "#28429e", textAlign: 'center', textTransform: "uppercase", marginBottom: 40 }} gutterBottom >
            {t['form.upload.title']}
          </Typography> 

          <Typography style={{ color: "#28429e", fontSize: 18, border: "2px solid #28429e", padding: "14px 25px", borderRadius: 4, margin: 10, marginBottom: 30, textAlign: 'center' }}><b>{t['form.upload.subtitle']}</b>
          </Typography>          
    
          
          <Preview setState={setState} state={state} t={t} /> 
          

          {/* 
          <input type="file" id="fileTester" name="files" multiple />
          */}

          <div style={{ "justifyContent": "flex-end", display: "flex" }}>
            <Button type="submit" size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "50px 10px", padding: "5px 120px", "textTransform": "none", "borderRadius": "2px" }} >
                  {t['form.submit.title']}
            </Button>
          </div>        
        </form>
      </Card> )

    }
};

Form.contextTypes = {
  router: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};
const mapDispatchToProps = (dispatch) => ({

});

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(Form));
