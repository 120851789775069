import React from 'react';
import PropTypes from 'prop-types';

import { BASE_URL } from '../consts';
import { ResetForm } from '../components';
import Auth from '../modules/Auth';

class ResetPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    const storedMessage = localStorage.getItem('successMessage');
    let successMessage = '';

    if (storedMessage) {
      successMessage = storedMessage;
      localStorage.removeItem('successMessage');
    }

    const search = new URLSearchParams(window.location.search);
    const token = search.get('token');

    if (!token){
      this.context.router.history.push('/');
    }

    this.state = {
      errors: {},
      successMessage,
      password: '',
      confirm: '',
      token
    };

    this.processForm = this.processForm.bind(this);
    this.onChange = this.onChange.bind(this);

  }

  processForm(event) {
    event.preventDefault();

    this.setState({
      errors: {},
      loading: true
    })

    const password = encodeURIComponent(this.state.password);
    const confirm = encodeURIComponent(this.state.confirm);
    const token = encodeURIComponent(this.state.token);
    const formData = `confirm=${confirm}&password=${password}&passwordResetToken=${token}`;

    const xhr = new XMLHttpRequest();
    xhr.open('post', `${BASE_URL}/api/v1/account/reset_password`);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.responseType = 'json';
    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {

        localStorage.setItem('successMessage', 'Your password has been successfully reset.');

        this.context.router.history.push('/')
      } else {
        const errors = {};

        if (xhr.response.validationErrors){
          errors.summary = "Password validation failed. Please check your password and try again.";
        } else if (xhr.response.error === "tokenExpired"){
          errors.summary = "Password reset token is invalid or has expired. Please resubmit password reset request.";
        } else {
          errors.summary = "Unknown error has occured. Please contact customer support.";
        }

        console.log(xhr.response)

        this.setState({ errors });
      }
    });
    xhr.addEventListener('loadend', this.setState({ loading: false }));    
    xhr.send(formData);
  }

  onChange(event) {
    const field = event.target.name;
    const user = this.state.user;
    this.state[field] = event.target.value;

    this.setState(this.state);
  }

  render() {
    return (
      <ResetForm
        onSubmit={this.processForm}
        onChange={this.onChange}
        errors={this.state.errors}
        state={this.state}
        loading={this.state.loading}
      />
    );
  }

}


ResetPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default ResetPage;
