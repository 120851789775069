import { generate_xhr } from '../utils';
import { BASE_URL } from '../consts';

class Auth {

  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser(token, user) {

    localStorage.setItem('token', token);
    localStorage.setItem('user', user);
  }

  static updateUser(user) {
    localStorage.setItem('user', user);
  }  

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return localStorage.getItem('token') !== null;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    return localStorage.getItem('token');
  }
  static getUser() {
    return localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  }  

  static authenticate(){
    if (this.getToken()){
      generate_xhr()(
        'post', 
        `${BASE_URL}/api/v1/authenticate`, 
        xhr => e => {},
        xhr => e => {
          if (xhr.status === 200) {
            this.updateUser(xhr.response)
          } else {
            this.deauthenticateUser();
          }
        },
        xhr => e => {},
      )      
    }
  }





















}

export default Auth;
