import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import { withStyles } from '@material-ui/core/styles';

import Lang from '../modules/Lang';

import { formDataSubmit } from '../utils';
import { BASE_URL } from '../consts';
import Auth from './../modules/Auth';

import { ThumbIcon, ApplicationStatus, PolicyButton, AssetThumbs, ApplicationOrderRow, Loader, ErrorCard, Preview } from './../elements';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    // margin: '50px',
  },
});

class Application extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      files: [],   

      alert: false,
      alertContents: "",   
      alertStatus: false
    };

    this.handleModal = this.handleModal.bind(this);
  }

  handleModal = (value) => (event) => {
    this.setState({ modal: value })
  }

  submitApplicationUpdate = () => {

    const { storage, order, setState, state, fetchData } = this.props;
    const { language } = storage;
    const t = Lang[language];

    const files = [...this.state.files]


    var formData  = new FormData();

    files.forEach( file => formData.append('files', file ) );
    formData.append('id', order._id);

    this.setState({ modal: false })

    const h = this.context.router.history;
    formDataSubmit(h)(
      formData,
      `${BASE_URL}/api/v1/applications/update/assets`,
      () => setState({ 'loading.update': true }),
      data => { 
        if (data.validationErrors){
          setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.validationErrors.map(e => e.msg).reduce((acc, cur) => { return `${acc}\n${cur}`}, "")
          })    
        } else if (data.err) {
          setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: data.err
          })    
        } else if (!data.output){
          setState({ 
            alert: true,
            alertStatus: "danger",
            alertContents: t['application.update.message.unknown_error']
          })             
        } else {
          setState({ 
            alert: true,
            alertStatus: "success",
            alertContents: t['application.update.message.success'],
            // order: data.output
          })               
          fetchData();
        }
      },
      e => {
        console.log(e);
        setState({ 
          alert: true,
          alertStatus: "danger",
          alertContents: e
        })          
      },
      () => { 
        setState({ 'loading.update': false }) 
      },
    ) 
  }

  render(){

    const { classes, organizations, order, match, error, storage, fetchData, loading } = this.props;
    const { language } = storage;
    const t = Lang[language];    

    if (error == 400 || !order ){
      return (
        <ErrorCard icon={"fa-ghost"} header={t['application.error.not_found']} subtitle={ `${t['application.error.application']} ${match.params.id} ${t['application.error.is_not_available']}`} />
      )
    } else if (error) {
      return (
        <ErrorCard icon={"fa-times"} header={t['application.error.unknown']} subtitle={t['application.error.contact_support']} />
      )
    }

    return (
      <Card className="container" style={{ width: "90%", "textAlign": "left", padding: "50px 55px", }}>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.modal}
          onClose={this.handleModal(false)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={classes.paper}>
            <Typography style={{ fontSize: 30, color: "#28429e", textAlign: 'center', textTransform: "uppercase", margin: "40px 0px" }} gutterBottom >
                  {t['form.upload.title']}
            </Typography> 

            <Preview setState={this.setState.bind(this)} state={this.state} t={t} /> 

            <div style={{ "justifyContent": "flex-end", display: "flex" }}>
              <Button onClick={this.submitApplicationUpdate}
                type="submit" size="large" variant="contained" color="primary" style={{ fontSize: 20, margin: "50px 10px", padding: "5px 120px", "textTransform": "none", "borderRadius": "2px" }} >
                {t['form.submit.title']}
              </Button>
            </div>

          </div>
        </Modal>

        <Loader loading={loading} >
          { order && Object.keys(order).length > 0 && <div>

            <div style={{ display: 'flex' }}>
              <div style={{ width: '65%' }}>

                <div style={{  width: '100%', padding: "4px 0", display: 'flex', marginBottom: 0 }}>
                  <Typography style={{ fontSize: 16, width: '50%',  textTransform: "uppercase", fontWeight: 'bold' }} gutterBottom >
                    {t['confirm.application.title']} 
                  </Typography>           
                </div>   

                <ApplicationStatus order={order} t={t} />
   
                <ApplicationOrderRow 
                  title={t['confirm.summary.project_number']} 
                  value={ order.contract.project_number }
                />
                <ApplicationOrderRow 
                  title={t['confirm.summary.contractor']} 
                  value={ order.contractor.name }
                />
                <ApplicationOrderRow 
                  title={t['confirm.summary.sum']} 
                  value={ order.contract.sum.replace(/(.)(?=(.{3})+$)/g,"$1 ") }
                />

                { order.type === "Fullgörande" &&
                  <div> 
                    <ApplicationOrderRow 
                      title={t['confirm.summary.while_contract.type']} 
                      value={ <div>
                        <i className="fas fa-flag" style={{ color: '#28429e'}} /> {t['confirm.summary.while_contract.type_name']}
                      </div> }
                    />
                    <ApplicationOrderRow 
                      title={t['confirm.summary.while_contract.start_date']} 
                      value={ moment(order.start_date).format("YYYY-MM-DD") }
                    />
                    <ApplicationOrderRow 
                      title={t['confirm.summary.while_contract.end_date']} 
                      value={ moment(order.end_date).format("YYYY-MM-DD") }
                    />
                    <ApplicationOrderRow 
                      title={t['confirm.summary.while_contract.amount']} 
                      value={ `${order.amount}% ${t['confirm.summary.while_contract.of_sum']}` }
                    />
                  </div>
                }   

                { order.type === "Garantitid" &&
                  <div>
                    <ApplicationOrderRow 
                      title={t['confirm.summary.while_warranty.type']} 
                      value={ <div><i className="fas fa-flag" style={{ color: '#28429e'}} /> {t['confirm.summary.while_warranty.type_name']}</div> }
                    />
                    <ApplicationOrderRow 
                      title={t['confirm.summary.while_warranty.start_date']} 
                      value={ moment(order.start_date).format("YYYY-MM-DD") }
                    />
                    <ApplicationOrderRow 
                      title={t['confirm.summary.while_warranty.end_date']} 
                      value={ moment(order.end_date).format("YYYY-MM-DD") }
                    />
                    <ApplicationOrderRow 
                      title={t['confirm.summary.while_warranty.amount']} 
                      value={ `${order.amount}% ${t['confirm.summary.while_warranty.of_sum']}` }
                    />
                  </div>
                }    

                <ApplicationOrderRow 
                  title={t['confirm.summary.insurance_number']} 
                  value={ order.insurance_number || 'N/A' }
                />  
                <ApplicationOrderRow 
                  title={t['confirm.summary.control_number']} 
                  value={ order.control_number || 'N/A' }
                />   
                <ApplicationOrderRow 
                  title={''} 
                  value={ order._id }
                  valueStyles={{fontWeight: 'bold' }}
                />                                                        

                <AssetThumbs t={t} handleFn={this.handleModal} order={order} />          

              </div>
            </div>

            <PolicyButton t={t} order={order} />

          </div> }        
        </Loader>

      </Card> )

    }
};


Application.contextTypes = {
  router: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(Application));