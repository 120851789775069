import React from 'react';
import Auth from '../modules/Auth';

import { Profile } from '../components';

class ProfilePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {

  }

  render() {
    return (<Profile />);
  }

}

export default ProfilePage;
