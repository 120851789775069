import Auth from './modules/Auth';

export const localeMap = {
  en: "en",
  sv: "sv",
  fi: "fi",
};

export const generate_xhr = h => (method, url, loadstart, load, loadend) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.setRequestHeader('Authorization', `Bearer ${Auth.getToken()}`);

    xhr.addEventListener('loadstart', loadstart(xhr));
    xhr.addEventListener('load', load(xhr));
    xhr.onerror = function (e) {
      // console.log(e);
      alert("** An error occurred during the transaction **");
      h && h.push('/logout')
    };  
    xhr.addEventListener('loadend', () => {
        if (xhr.status === 401 || xhr.status >= 500) {
            h && h.push('/logout')
        }
    });      
    xhr.addEventListener('loadend', loadend(xhr));      
    xhr.send();
  }

export const fetchSubmit = h => (data, url, onBefore, onSuccess, onError, onFinally) => {
    onBefore();
    fetch(url, {            
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Auth.getToken()}`
      },
      body: JSON.stringify(data)
    })
    .then(response => { 
        if (response.status === 401 || response.status >= 500){
            h && h.push('/logout')
        } else {
            return response; 
        }
    })
    .then(response => response.json())
    .then(data => onSuccess(data))
    .catch(e => onError(e))
    .finally(() => onFinally());
}

export const formDataSubmit = h => (formData, url, onBefore, onSuccess, onError, onFinally) => {
    onBefore();
    fetch(url, {            
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${Auth.getToken()}`
      },
      body: formData
    })
    .then(response => { 
        if (response.status === 401 || response.status >= 500){
            h && h.push('/logout')
        } else {
            return response; 
        }
    })    
    .then(response => response.json())
    .then(data => onSuccess(data))
    .catch(e => onError(e))
    .finally(() => onFinally());
  }

export const getLanguageHeader = language => language === "sv" ? "Svenska" :
                       language === "en" ? "English" : 
                       language === "fi" ? "Suomi" :
                       null;

export const getLanguageIcon = language => language === "sv" ? "sweden.png" :
                     language === "en" ? "united-kingdom.png" : 
                     language === "fi" ? "finland.png" :
                     null;