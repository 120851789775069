import React from 'react';
import TextField from '@material-ui/core/TextField';

export default class ProfileField extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { required, type, label, placeholder, onChangeFn } = this.props;

    return (
      <TextField
        required={required}
        type={type || "text"}
        label={label}
        style={{ margin: "8px 0", width: '50%' }}
        placeholder={placeholder}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChangeFn}
      /> 
    );
  }

}
