const ENGLISH = {

	'nav.applications.all': "My applications",
	'nav.applications.new': "New application",
	'nav.contact': "Contact",
	'nav.profile': "Profile",
	'nav.profile.settings': "Settings",
	'nav.logout': "Log out",


	'new.application.message.unknown_error': "An error has occured while submitting your application. Please contact customer support if the error persist.",		
	'new.application.message.success': "Your application has been successfully submitted.",
	'new.application.message.validation_error': "Some input fields are missing or invalid. Please check your form.",	

	'application.update.message.unknown_error': "An error has occured while updating your application. Please contact customer support if the error persist.",		
	'application.update.message.success': "Your application has been successfully updated.",	


	'form.applicant.subtitle1': "Always attach copies of:",
	'form.applicant.subtitle2': "contract of contract, timetable and payment plan and other documents that the policyholder wishes to rely on.",
	'form.applicant.subtitle3': "A credit for the contractor will be collected when the application is processed.",	

	'form.input.placeholder': "Type here…",
	'form.input.required.noValueError': "This field is required!",


	'form.broker.title': "Organization",
	'form.broker.organization.title': "Organization",

	'form.applicant.title': "Policyholder",
	'form.applicant.name.title': "Policyholder / Buyer",
	'form.applicant.organization.title': "Organization number",
	'form.applicant.address.title': "Post address",
	'form.applicant.invoice_declaration.title': "The invoice shall state (e.g. Invoice reference, Customer ID, Project name, Contract or Project number, etc)",
	'form.applicant.invoice_address.title': "Invoice address / E-invoice",
	'form.applicant.zip.title': "Zip code",
	'form.applicant.city.title': "City",
	'form.applicant.contact.title': "Contact person",
	'form.applicant.contact.add': "Add another contact person",
	'form.applicant.contact.remove': "Remove",
	'form.applicant.phone.title': "Phone number",
	'form.applicant.phone_vaxel.title': "Phone number",
	'form.applicant.phone_mobile.title': "Mobile number",
	'form.applicant.email.title': "E-mail address",
	'form.applicant.email.typeMismatchError': "Email is invalid!",

	'form.contractor.title': "Contractor",
	'form.contractor.name.title': "Contractor",	
	'form.contractor.organization.title': "Organization",
	'form.contractor.address.title': "Post address",
	'form.contractor.zip.title': "Zip code",
	'form.contractor.city.title': "City",
	'form.contractor.contact.title': "Contact person",
	'form.contractor.contact.add': "Add another contact person",
	'form.contractor.contact.remove': "Remove",
	'form.contractor.phone.title': "Phone number",
	'form.contractor.phone_vaxel.title': "Phone number",
	'form.contractor.phone_mobile.title': "Mobile number",
	'form.contractor.email.title': "E-mail address",
	'form.contractor.email.typeMismatchError': "Email is invalid!",

	'form.subcontract.checkbox.label': "Include sub-contractor?",

	'form.subcontractor.title': "Sub-contractor",
	'form.subcontractor.name.title': "Sub-contractor",	
	'form.subcontractor.organization.title': "Organization",
	'form.subcontractor.address.title': "Post address",
	'form.subcontractor.zip.title': "Zip code",
	'form.subcontractor.city.title': "City",
	'form.subcontractor.contact.title': "Contact person",
	'form.subcontractor.contact.add': "Add another contact person",
	'form.subcontractor.contact.remove': "Remove",
	'form.subcontractor.phone.title': "Phone number",
	'form.subcontractor.phone_vaxel.title': "Phone number",
	'form.subcontractor.phone_mobile.title': "Mobile number",
	'form.subcontractor.email.title': "E-mail address",
	'form.subcontractor.email.typeMismatchError': "Email is invalid!",	

	'form.contract.title': "Project details",
	'form.contract.building.title': "Property designation",
	'form.contract.work_area.title': "Site (city or area)",	
	'form.contract.project_number.title': "Project and/or Contract Identificator",
	'form.contract.agreement_date.title': "Contract date",
	'form.contract.sum.title': "Contract value",
	'form.contract.concerns.title': "Contract concerns",	
	'form.contract.start_date.title': "Expected start date",
	'form.contract.end_date.title': "Expected date for final inspection",	
	
	'form.guarantee.title': "Details about the Performance Guarantee",
	'form.guarantee.while_contract.title': "Performance gurarantee for construction period",	
	'form.guarantee.while_contract_amount_percent.title': "Sum insured (% of contract value)",
	'form.guarantee.while_warranty.title': "Performance gurarantee for warranty period",
	'form.guarantee.while_warranty_amount_percent.title': "Sum insured (% of contract value)",
	'form.guarantee.while_warranty_period.title': "Insurance period",
	'form.guarantee.while_contract_amount_percent.other': "other",	
	'form.guarantee.while_warranty_amount_percent.other': "other",	
	'form.guarantee.while_warranty_period.2': "2 years",
	'form.guarantee.while_warranty_period.5': "5 years",
	'form.guarantee.while_warranty_period.other': "",
	'form.guarantee.while_warranty_period.year': "years",


	'form.upload.title': "Upload documents",
	'form.upload.subtitle': "Upload documents that the policyholder wants to rely on, eg. Contract, Schedule and Payment Plan",
	'form.upload.drag.title': "Drag a file here",
	'form.upload.selected_files.title': "Selected files",
	'form.upload.no_selected.title': "No files selected",
	// 'form.guarantee.address.title': "Eller, om du föredrar…",	
	// 'form.guarantee.address.title': "Välj en fil från din dator",		
	'form.submit.title': "Submit",			
			
	// 'form.guarantee.address.title': "Summering",	
	// 'form.guarantee.address.title': "Tack för din beställning!",						
	// 'form.guarantee.address.title': "Er ansökan behandlas nu av vyearst team. Vi kontaktar dig inom kort. Med detta konto, kan du; se status på er ansökan, addera ytterligare dokument eller kontakt vyears kundservice.",							


	'confirm.applicant.title': "Policyholder",
	'confirm.applicant.name.title': "Policyholder / Buyer",
	'confirm.applicant.organization.title': "Organization",
	'confirm.applicant.invoice_declaration.title': "The invoice shall state (e.g. Invoice reference, Customer ID, Project name, Contract or Project number, etc)",
	'confirm.applicant.invoice_address.title': "Invoice address / E-invoice",	
	'confirm.applicant.address.title': "Post address",
	'confirm.applicant.zip.title': "Zip code",
	'confirm.applicant.city.title': "City",
	'confirm.applicant.contact.title': "Contact person",
	'confirm.applicant.phone.title': "Phone number",
	'confirm.applicant.phone_vaxel.title': "Phone number",
	'confirm.applicant.phone_mobile.title': "Mobile number",
	'confirm.applicant.email.title': "E-mail address",

	'confirm.contractor.title': "Contractor",
	'confirm.contractor.name.title': "Contractor",	
	'confirm.contractor.organization.title': "Organization",
	'confirm.contractor.address.title': "Post address",
	'confirm.contractor.zip.title': "Zip code",
	'confirm.contractor.city.title': "City",
	'confirm.contractor.contact.title': "Contact person",
	'confirm.contractor.phone.title': "Phone number",
	'confirm.contractor.phone_vaxel.title': "Phone number",
	'confirm.contractor.phone_mobile.title': "Mobile number",
	'confirm.contractor.email.title': "E-mail address",

	'confirm.subcontractor.title': "Sub-contractor",
	'confirm.subcontractor.name.title': "Sub-contractor",	
	'confirm.subcontractor.organization.title': "Organization",
	'confirm.subcontractor.address.title': "Post address",
	'confirm.subcontractor.zip.title': "Zip code",
	'confirm.subcontractor.city.title': "City",
	'confirm.subcontractor.contact.title': "Contact person",
	'confirm.subcontractor.phone.title': "Phone number",
	'confirm.subcontractor.phone_vaxel.title': "Phone number",
	'confirm.subcontractor.phone_mobile.title': "Mobile number",
	'confirm.subcontractor.email.title': "E-mail address",	

	'confirm.contract.title': "Project details",
	'confirm.contract.building.title': "Property designation",
	'confirm.contract.work_area.title': "Site (city or area)",	
	'confirm.contract.project_number.title': "Project and/or Contract Identificator",
	'confirm.contract.agreement_date.title': "Contract date",
	'confirm.contract.sum.title': "Contract value SEK",
	'confirm.contract.concerns.title': "Contract concerns",	
	'confirm.contract.start_date.title': "Expected start date",
	'confirm.contract.end_date.title': "Expected date for final inspection",	
	
	// 'confirm.guarantee.title': "Details about the Performance Guarantee",
	// 'confirm.guarantee.while_contract.title': "Performance gurarantee construction period",	
	// 'confirm.guarantee.while_contract_amount_percent.title': "Sum insured (% of contract value)",
	// 'confirm.guarantee.while_warranty.title': "Sum insured (% of contract value)",
	// 'confirm.guarantee.while_warranty_amount_percent.title': "Performance gurarantee warranty period",
	// 'confirm.guarantee.while_warranty_period.title': "Insurance period",

	'confirm.summary.title': "Summary",
	'confirm.summary.project_number': "Project name",
	'confirm.summary.contractor': "Contractor",
	'confirm.summary.while_contract.type': "Type",
	'confirm.summary.while_contract.amount': "Insured amount",
	'confirm.summary.while_contract.start_date': "Start date",
	'confirm.summary.while_contract.end_date': "End date",
	'confirm.summary.while_warranty.type': "Type",
	'confirm.summary.while_warranty.amount': "Insured amount",
	'confirm.summary.while_warranty.start_date': "Start date",
	'confirm.summary.while_warranty.end_date': "End date",	
	'confirm.summary.while_contract.type_name': "Performance Construction Period",
	'confirm.summary.while_warranty.type_name': "Performance Warranty Period",

	'confirm.summary.insurance_number': "Policy number",
	'confirm.summary.control_number': "Control number",	

	'confirm.summary.sum': "Contract sum SEK",
	'confirm.summary.while_contract.of_sum': "of contract sum",
	'confirm.summary.while_warranty.of_sum': "of contract sum",

	'confirm.summary.attachments.title': "Attachments",
	'confirm.summary.attachments.no_selected.title': "No files selected",		

	'confirm.submit.title': "Submit",			
	'confirm.submit.back': "Back to form",	




	'confirm.application.title': "Application",
	'confirm.status.title': "Status",
	'confirm.status.active': "Active",
	'confirm.status.rejected': "Rejected",
	'confirm.status.waiting': "Waiting",
	'confirm.status.waiting_for_approval': "Waiting for approval",


	'thankyou.title': "Thank you for the order!",
	'thankyou.subtitle1': "Your application is now reviewed by our team. We will contact you soon! By using this RSG account, you can;",
	'thankyou.subtitle2': "check the status of your application, add additional document or contact our customer service.",	

	'thankyou.attachments.title': "Attachments",
	'thankyou.attachments.no_selected.title': "No files selected",		

	'thankyou.submit.back_to_applications': "My applications",		


	'contact.intro': "Hello, my name is",
	'contact.name': "Thomas Fredriksson",
	'contact.email': "thomas.fredriksson@rsgsweden.com",
	'contact.or': "or",
	'contact.phone': "076-795 55 75",
	'contact.from': "From",
	'contact.to': "To",
	'contact.incase': "In case of any questions, please contact me by",

	'contact.subject.title': "Subject",
	'contact.subject.placeholder': "Subject",
	'contact.message.title': "Message",
	'contact.message.placeholder': "Type here…",	

	'contact.submit.title': "Submit",		

	'dashboard.organizationFilter.title': "Filter by organization",

	'dashboard.application.new': "New application",
	'dashboard.application.export': "Export to Excel",
	'dashboard.application.unconfirmed.title': "Applications",
	'dashboard.application.confirmed.title': "Confirmed policies",
	'dashboard.application.paid.title': "Payed policies",
	'dashboard.application.expired.title': "Expired policies",
	'dashboard.application.rejected.title': "Rejected policies",
	'dashboard.application.id': "Control number",
	'dashboard.application.product_type': "Product type",
	'dashboard.application.contractor': "Contractor",
	'dashboard.application.subcontractor': "Sub-contractor",
	'dashboard.application.project_number': "Project name",
	'dashboard.application.start_date': "Start date",
	'dashboard.application.contact': "Contact person",
	'dashboard.application.policy': "Policy",
	'dashboard.application.invoice': "Premium invoice",
	'dashboard.application.attachments': "Attachments",
	'dashboard.application.status': "Status",

	'dashboard.application.waiting_for_approval': "Waiting for approval",
	'dashboard.application.active': "Active",
	'dashboard.application.rejected': "Rejected",
	'dashboard.application.waiting': "Waiting",	
	'dashboard.application.unpaid': "Unpaid premium",	
	'dashboard.application.paid': "Paid and Effective",	
	'dashboard.application.expired': "Expired",	

	'dashboard.application.row_per_page_label': "Rows per page:",

	'dashboard.aria.first_page': "First Page",	
	'dashboard.aria.prev_page': "Previous Page",	
	'dashboard.aria.next_page': "Next Page",	
	'dashboard.aria.last_page': "Last Page",	

	'dashboard.invoice.title': "Invoices",
	'dashboard.invoice.id': "Order ID",
	'dashboard.invoice.application': "Application ID",
	'dashboard.invoice.payment_due': "Payment due",
	'dashboard.invoice.price': "Price",
	'dashboard.invoice.file': "Invoice file",


	'profile.user.header': "Profile information",	
	'profile.user.name.header': "Name",	
	'profile.user.email.header': "Email",	
	'profile.user.phone.header': "Phone",	
	'profile.user.name.title': "Name",	
	'profile.user.email.title': "Email",	
	'profile.user.phone.title': "Phone",
	'profile.user.name.placeholder': "Name",	
	'profile.user.email.placeholder': "Email",	
	'profile.user.phone.placeholder': "Phone",			
	'profile.user.submit.title': "Submit",		

	'profile.user.message.unknown_error': "An error has occured while updating your profile. Please contact customer support if the error persist.",		
	'profile.user.message.success': "Your profile has been successfully updated.",

	'profile.password.header': "Change password",		
	'profile.password.current.title': "Current password",		
	'profile.password.new.title': "New password",		
	'profile.password.confirm.title': "Confirm new password",	
	'profile.password.current.placeholder': "Current password",		
	'profile.password.new.placeholder': "New password",		
	'profile.password.confirm.placeholder': "Confirm new password",		
	'profile.password.submit.title': "Submit",		

	'profile.password.message.unknown_error': "An error has occured while updating your profile. Please contact customer support if the error persist.",		
	'profile.password.message.success': "Your profile has been successfully updated.",	


	'application.title': "Application",
	'application.status.title': "Status",
	'application.status.active': "Active",
	'application.status.rejected': "Rejected",
	'application.status.waiting': "Waiting",
	'application.status.waiting_for_approval': "Waiting for approval",

	'application.attachments.title': "Attachments",
	'application.attachments.no_selected.title': "No files selected",		

	'application.submit.download_policy': "Download policy letter (pdf)",

	'application.error.not_found': "Application not found",
	'application.error.application': "Application",
	'application.error.is_not_available': "is not available.",
	'application.error.unknown': "An error has occured",
	'application.error.contact_support': "Please contact customer support if the error persist",


	'invoice.title': "Invoice",	

	'invoice.submit.download_invoice': "Download invoice (pdf)",

	'invoice.error.not_found': "Invoice not found",
	'invoice.error.invoice': "Invoice",
	'invoice.error.is_not_available': "is not available.",
	'invoice.error.unknown': "An error has occured",
	'invoice.error.contact_support': "Please contact customer support if the error persist",
}

const FINNISH = {
	
}

const SWEDISH = {

	'nav.applications.all': "Sammanställning",
	'nav.applications.new': "Ny ansökan",
	'nav.contact': "Support",
	'nav.profile': "Profil",
	'nav.profile.settings': "Inställningar",
	'nav.logout': "Logga ut",


	'new.application.message.unknown_error': "An error has occured while submitting your application. Please contact customer support if the error persist.",		
	'new.application.message.success': "Your application has been successfully submitted.",
	'new.application.message.validation_error': "Some input fields are missing or invalid. Please check your form.",

	'application.update.message.unknown_error': "An error has occured while updating your application. Please contact customer support if the error persist.",		
	'application.update.message.success': "Your application has been successfully updated.",	


	'form.applicant.subtitle1': "Bifoga alltid kopior av:",
	'form.applicant.subtitle2': "entreprenadkontrakt, tidplan och betalplan samt övriga handlingar som försäkringstagaren önskar åberopa.",
	'form.applicant.subtitle3': "En kreditupplysning avseende entreprenören kommer att inhämtas i samband med att ansökan behandlas.",	

	'form.input.placeholder': "Skriv här…",
	'form.input.required.noValueError': "Detta fält är obligatoriskt!",

	'form.broker.title': "Organisation",
	'form.broker.organization.title': "Organisations namn",

	'form.applicant.title': "Försäkringstagare",
	'form.applicant.name.title': "Försäkringstare / Beställare",
	'form.applicant.organization.title': "Organisationsnummer",
	'form.applicant.address.title': "Postadress",
	'form.applicant.invoice_declaration.title': "På faktura anges (t.ex. Fakturareferens, Beställar-ID, Projektnamn, Avtals-, Projektnummer, etc)",
	'form.applicant.invoice_address.title': "Fakturaadress / E-faktura",	
	'form.applicant.zip.title': "Postnummer",
	'form.applicant.city.title': "Postort",
	'form.applicant.contact.title': "Kontaktperson",
	'form.applicant.contact.add': "Ev. annan kontaktperson",
	'form.applicant.contact.remove': "Remove",
	'form.applicant.phone.title': "Telefonnummer",
	'form.applicant.phone_vaxel.title': "Telefon, växel",
	'form.applicant.phone_mobile.title': "Mobiltelefon",
	'form.applicant.email.title': "E-postadress",
	'form.applicant.email.typeMismatchError': "E-postadressen är felaktig!",

	'form.contractor.title': "Entreprenör",
	'form.contractor.name.title': "Entreprenör",	
	'form.contractor.organization.title': "Organisationsnummer",
	'form.contractor.address.title': "Postadress",
	'form.contractor.zip.title': "Postnummer",
	'form.contractor.city.title': "Postort",
	'form.contractor.contact.title': "Kontaktperson",
	'form.contractor.contact.add': "Ev. annan kontaktperson",
	'form.contractor.contact.remove': "Remove",
	'form.contractor.phone.title': "Telefonnummer",
	'form.contractor.phone_vaxel.title': "Telefon, växel",
	'form.contractor.phone_mobile.title': "Mobiltelefon",
	'form.contractor.email.title': "E-postadress",
	'form.contractor.email.typeMismatchError': "E-postadressen är felaktig!",

	'form.subcontract.checkbox.label': "Inkludera underentreprenör (UE) i ansökan?",

	'form.subcontractor.title': "Underentreprenör (UE)",
	'form.subcontractor.name.title': "Underentreprenör (UE)",	
	'form.subcontractor.organization.title': "Organisationsnummer",
	'form.subcontractor.address.title': "Postadress",
	'form.subcontractor.zip.title': "Postnummer",
	'form.subcontractor.city.title': "Postort",
	'form.subcontractor.contact.title': "Kontaktperson",
	'form.subcontractor.contact.add': "Ev. annan kontaktperson",
	'form.subcontractor.contact.remove': "Remove",
	'form.subcontractor.phone.title': "Telefonnummer",
	'form.subcontractor.phone_vaxel.title': "Telefon, växel",
	'form.subcontractor.phone_mobile.title': "Mobiltelefon",
	'form.subcontractor.email.title': "E-postadress",
	'form.subcontractor.email.typeMismatchError': "E-postadressen är felaktig!",	

	'form.contract.title': "Uppgift om entreprenaden",
	'form.contract.building.title': "Fastighets-/objektsbeteckning",
	'form.contract.work_area.title': "Arbetsområde (ort)",	
	'form.contract.project_number.title': "Projektnamn och Avtal-/Projektnummer",
	'form.contract.agreement_date.title': "Kontraktsdatum",
	'form.contract.sum.title': "Kontraktsumma",
	'form.contract.concerns.title': "Entreprenaden avser",	
	'form.contract.start_date.title': "Beräknat datum för påbörjande",
	'form.contract.end_date.title': "Beräknat datum för slutbesiktning/avlämnande",	
	
	'form.guarantee.title': "Uppgift om sökt garanti",
	'form.guarantee.while_contract.title': "Fullgörandegaranti under entreprenadtid",	
	'form.guarantee.while_contract_amount_percent.title': "Försäkringsbelopp (% av kontraktssumman)",
	'form.guarantee.while_warranty.title': "Fullgörandegaranti under garantitid",
	'form.guarantee.while_warranty_amount_percent.title': "Försäkringsbelopp (% av kontraktssumman)",
	'form.guarantee.while_warranty_period.title': "Försäkringsperiod",
	'form.guarantee.while_contract_amount_percent.other': "annan",	
	'form.guarantee.while_warranty_amount_percent.other': "annan",
	'form.guarantee.while_warranty_period.2': "2 år",
	'form.guarantee.while_warranty_period.5': "5 år",
	'form.guarantee.while_warranty_period.other': "annan tid",
	'form.guarantee.while_warranty_period.year': "år",

	'form.upload.title': "Ladda upp dokument",
	'form.upload.subtitle': "Ladda upp underlag som försäkringstagaren vill åberopa, t.ex. Entreprenadavtal, Tidplan och Betalplan",
	'form.upload.drag.title': "Dra en fil hit",
	'form.upload.selected_files.title': "Selected files",
	'form.upload.no_selected.title': "No files selected",
	// 'form.guarantee.address.title': "Eller, om du föredrar…",	
	// 'form.guarantee.address.title': "Välj en fil från din dator",		
	'form.submit.title': "Förhandsgranska ansökan",				
			
	// 'form.guarantee.address.title': "Summering",	
	// 'form.guarantee.address.title': "Tack för din beställning!",						
	// 'form.guarantee.address.title': "Er ansökan behandlas nu av vårt team. Vi kontaktar dig inom kort. Med detta konto, kan du; se status på er ansökan, addera ytterligare dokument eller kontakt vår kundservice.",							


	'confirm.applicant.title': "Försäkringstagare",
	'confirm.applicant.name.title': "Försäkringstare / Beställare",
	'confirm.applicant.organization.title': "Organisationnummer",
	'confirm.applicant.invoice_declaration.title': "På faktura anges (t.ex. Fakturareferens, Beställar-ID, Projektnamn, Avtals-, Projektnummer, etc)",
	'confirm.applicant.invoice_address.title': "Fakturaadress / E-faktura",		
	'confirm.applicant.address.title': "Postadress",
	'confirm.applicant.zip.title': "Postnummer",
	'confirm.applicant.city.title': "Postort",
	'confirm.applicant.contact.title': "Kontaktperson",
	'confirm.applicant.phone.title': "Telefonnummer",
	'confirm.applicant.phone_vaxel.title': "Telefon, växel",
	'confirm.applicant.phone_mobile.title': "Mobiltelefon",
	'confirm.applicant.email.title': "E-postadress",

	'confirm.contractor.title': "Entreprenör",
	'confirm.contractor.name.title': "Entreprenör",	
	'confirm.contractor.organization.title': "Organisationnummer",
	'confirm.contractor.address.title': "Postadress",
	'confirm.contractor.zip.title': "Postnummer",
	'confirm.contractor.city.title': "Postort",
	'confirm.contractor.contact.title': "Kontaktperson",
	'confirm.contractor.phone.title': "Telefonnummer",
	'confirm.contractor.phone_vaxel.title': "Telefon, växel",
	'confirm.contractor.phone_mobile.title': "Mobiltelefon",
	'confirm.contractor.email.title': "E-postadress",

	'confirm.subcontractor.title': "Underentreprenör (UE)",
	'confirm.subcontractor.name.title': "Underentreprenör (UE)",	
	'confirm.subcontractor.organization.title': "Organisationnummer",
	'confirm.subcontractor.address.title': "Postadress",
	'confirm.subcontractor.zip.title': "Postnummer",
	'confirm.subcontractor.city.title': "Postort",
	'confirm.subcontractor.contact.title': "Kontaktperson",
	'confirm.subcontractor.phone.title': "Telefonnummer",
	'confirm.subcontractor.phone_vaxel.title': "Telefon, växel",
	'confirm.subcontractor.phone_mobile.title': "Mobiltelefon",
	'confirm.subcontractor.email.title': "E-postadress",	

	'confirm.contract.title': "Uppgift om entreprenaden",
	'confirm.contract.building.title': "Fastighets-/objektsbeteckning",
	'confirm.contract.work_area.title': "Arbetsområde (ort)",	
	'confirm.contract.project_number.title': "Projektnamn och Avtal-/Projektnummer",
	'confirm.contract.agreement_date.title': "Kontraktsdatum",
	'confirm.contract.sum.title': "Kontraktsumma SEK",
	'confirm.contract.concerns.title': "Entreprenaden avser",	
	'confirm.contract.start_date.title': "Beräknat datum för påbörjande",
	'confirm.contract.end_date.title': "Beräknat datum för slutbesiktning/avlämnande",	
	
	// 'confirm.guarantee.title': "Uppgift om sökt garanti",
	// 'confirm.guarantee.while_contract.title': "Fullgörandegaranti under entreprenadtid",	
	// 'confirm.guarantee.while_contract_amount_percent.title': "Försäkringsbelopp (% av kontraktssumman)",
	// 'confirm.guarantee.while_warranty.title': "Försäkringsbelopp (% av kontraktssumman)",
	// 'confirm.guarantee.while_warranty_amount_percent.title': "Fullgörandegaranti under garantitid",
	// 'confirm.guarantee.while_warranty_period.title': "Försäkringsperiod",

	'confirm.summary.title': "Förhandsgranskning av ansökan",
	'confirm.summary.project_number': "Projektnamn",
	'confirm.summary.contractor': "Entreprenör",
	'confirm.summary.while_contract.type': "Produkttyp",
	'confirm.summary.while_contract.type_name': "Fullgörande Entreprenadtid",
	'confirm.summary.while_contract.amount': "Försäkringsbelopp",
	'confirm.summary.while_contract.start_date': "Startdatum",
	'confirm.summary.while_contract.end_date': "Slutdatum",
	'confirm.summary.while_warranty.type': "Produkttyp",
	'confirm.summary.while_warranty.type_name': "Fullgörande Garantitid",
	'confirm.summary.while_warranty.amount': "Försäkringsbelopp",
	'confirm.summary.while_warranty.start_date': "Startdatum",
	'confirm.summary.while_warranty.end_date': "Slutdatum",	

	'confirm.summary.insurance_number': "Försäkringsnummer",
	'confirm.summary.control_number': "Kontrollnummer",
	
	'confirm.summary.sum': "Kontraktsumma SEK",
	'confirm.summary.while_contract.of_sum': "av kontraktsumman",
	'confirm.summary.while_warranty.of_sum': "av kontraktsumman",

	'confirm.summary.attachments.title': "Bilagor",
	'confirm.summary.attachments.no_selected.title': "No files selected",		

	'confirm.submit.title': "Skicka",			
	'confirm.submit.back': "Tillbaka",	




	'confirm.application.title': "Ansökan",
	'confirm.status.title': "Status",
	'confirm.status.active': "Gällande",
	'confirm.status.rejected': "Avslagen",
	'confirm.status.waiting': "Väntar",
	'confirm.status.waiting_for_approval': "Väntar på godkännande",

	'thankyou.title': "Tack för din beställning!",
	'thankyou.subtitle1': "Er ansökan behandlas nu av vårt team. Vi kontaktar dig inom kort. Med detta konto, kan du;",
	'thankyou.subtitle2': "se status på er ansökan, addera ytterligare dokument eller kontakt vår kundservice.",	

	'thankyou.attachments.title': "Bilagor",
	'thankyou.attachments.no_selected.title': "No files selected",		

	'thankyou.submit.back_to_applications': "Till sammanställning",		


	'contact.intro': "Hej, mitt namn är",
	'contact.name': "Thomas Fredriksson",
	'contact.email': "thomas.fredriksson@rsgsweden.com",
	'contact.or': "eller",
	'contact.phone': "076-795 55 75",
	'contact.from': "Från",
	'contact.to': "Till",
	'contact.incase': "Har ni några frågor, vänligen kontakta mig",

	'contact.subject.title': "Ämne",
	'contact.subject.placeholder': "Ämne",
	'contact.message.title': "Meddelande",
	'contact.message.placeholder': "Skriv här…",	

	'contact.submit.title': "Skicka",		

	'dashboard.organizationFilter.title': "Filtrera efter organisation",

	'dashboard.application.new': "Ny ansökan",
	'dashboard.application.export': "Exportera till Excel",
	'dashboard.application.unconfirmed.title': "Ansökningar",
	'dashboard.application.confirmed.title': "Bekräftade försäkringar",
	'dashboard.application.paid.title': "Betalda försäkringar",
	'dashboard.application.expired.title': "Upphörda försäkringar",
	'dashboard.application.rejected.title': "Avslagen",
	'dashboard.application.id': "Kontrollnummer",
	'dashboard.application.product_type': "Produkttyp",
	'dashboard.application.contractor': "Entreprenör",
	'dashboard.application.subcontractor': "Underentreprenör",
	'dashboard.application.project_number': "Projektnamn",
	'dashboard.application.start_date': "Startdatum",
	'dashboard.application.contact': "Kontaktperson",
	'dashboard.application.policy': "Försäkringsbrev",
	'dashboard.application.attachments': "Bilagor",
	'dashboard.application.invoice': "Premiefaktura",
	'dashboard.application.status': "Status",

	'dashboard.application.waiting_for_approval': "Väntar på godkännande",
	'dashboard.application.active': "Gällande",
	'dashboard.application.rejected': "Avslagen",
	'dashboard.application.waiting': "Väntar",	
	'dashboard.application.unpaid': "Obetald premie",	
	'dashboard.application.paid': "Betald och effektiv",	
	'dashboard.application.expired': "Utgånget",		

	'dashboard.application.row_per_page_label': "Rader per sida:",

	'dashboard.aria.first_page': "First Page",	
	'dashboard.aria.prev_page': "Previous Page",	
	'dashboard.aria.next_page': "Next Page",	
	'dashboard.aria.last_page': "Last Page",	

	'dashboard.invoice.title': "Fakturor",
	'dashboard.invoice.id': "Ordernummer",
	'dashboard.invoice.application': "Application ID",
	'dashboard.invoice.payment_due': "Payment due",
	'dashboard.invoice.price': "Price",
	'dashboard.invoice.file': "Invoice file",


	'profile.user.header': "Profil",	
	'profile.user.name.header': "Namm",	
	'profile.user.email.header': "E-postadress",	
	'profile.user.phone.header': "Telefonnummer",	
	'profile.user.name.title': "Namm",	
	'profile.user.email.title': "E-postadress",	
	'profile.user.phone.title': "Telefonnummer",
	'profile.user.name.placeholder': "Namm",	
	'profile.user.email.placeholder': "E-postadress",	
	'profile.user.phone.placeholder': "Telefonnummer",			
	'profile.user.submit.title': "Skicka",		

	'profile.user.message.unknown_error': "An error has occured while updating your profile. Please contact customer support if the error persist.",		
	'profile.user.message.success': "Your profile has been successfully updated.",

	'profile.password.header': "Ändra lösenord",		
	'profile.password.current.title': "Nuvarande lösenord",		
	'profile.password.new.title': "Nytt lösenord",		
	'profile.password.confirm.title': "Bekräfta nytt lösenord",	
	'profile.password.current.placeholder': "Nuvarande lösenord",		
	'profile.password.new.placeholder': "Nytt lösenord",		
	'profile.password.confirm.placeholder': "Bekräfta nytt lösenord",		
	'profile.password.submit.title': "Skicka",		

	'profile.password.message.unknown_error': "An error has occured while updating your profile. Please contact customer support if the error persist.",		
	'profile.password.message.success': "Your profile has been successfully updated.",	


	'application.title': "Ansökan",
	'application.status.title': "Status",
	'application.status.active': "Gällande",
	'application.status.rejected': "Avslagen",
	'application.status.waiting': "Väntar",
	'application.status.waiting_for_approval': "Väntar på godkännande",

	'application.attachments.title': "Bilagor",
	'application.attachments.no_selected.title': "No files selected",		

	'application.submit.download_policy': "Ladda ner försäkringsbrev (pdf)",

	'application.error.not_found': "Application not found",
	'application.error.application': "Application",
	'application.error.is_not_available': "is not available.",
	'application.error.unknown': "An error has occured",
	'application.error.contact_support': "Please contact customer support if the error persist",


	'invoice.title': "Faktura",	

	'invoice.submit.download_invoice': "Ladda ner faktura (pdf)",

	'invoice.error.not_found': "Invoice not found",
	'invoice.error.invoice': "Invoice",
	'invoice.error.is_not_available': "is not available.",
	'invoice.error.unknown': "An error has occured",
	'invoice.error.contact_support': "Please contact customer support if the error persist",

}






export default {
	en: ENGLISH,
	fi: FINNISH,
	sv: SWEDISH,
};

