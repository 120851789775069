import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

import Auth from '../modules/Auth';
import { generate_xhr } from '../utils';
import { BASE_URL } from '../consts';
import { Application } from '../components';

class ApplicationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      order: {},
      error: null,

      alert: false,
      alertContents: "",   
      alertStatus: "danger"

    };
  }

  check_load(){
    return this.state['loading.organizations'] || 
           this.state['loading.update'] || 
           this.state['loading.order'];
  }

  fetchData(){

    const h = this.context.router.history;
    generate_xhr(h)(
      'get', 
      `${BASE_URL}/api/v1/organizations`, 
      xhr => e => this.setState({ 'loading.organizations': true  }),
      xhr => e => {
        if (xhr.status === 200) {
          // console.log(JSON.parse(xhr.response))
          const organizations = JSON.parse(xhr.response)
          this.setState({
            organizations,
          });
        }
      },
      xhr => e => this.setState({ 'loading.organizations': false  }),
    )  

    generate_xhr(h)(
      'get', 
      `${BASE_URL}/api/v1/applications/view/${this.props.match.params.id}`, 
      xhr => e => this.setState({ 'loading.order': true  }),
      xhr => e => {
        console.log(e)
        if (xhr.status === 200) {
          console.log(JSON.parse(xhr.response))
          const order = JSON.parse(xhr.response)
          this.setState({
            order: order[0],
          });
        } else if (xhr.status){
          this.setState({ error: xhr.status });
        } else {
          this.setState({ error: -1 });
        }
      },
      xhr => e => this.setState({ 'loading.order': false  }),
    )      

  }

  componentDidMount() {
    this.fetchData();
  }

  onDismiss() {
    this.setState({ alert: false, alertContents: "" });
  }

  render() {
    const { organizations, order, error } = this.state;

    return (
      <div>
        { this.state.alert && 
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', position: 'fixed', width: '100%', zIndex: 2001, top: 0, textAlign: 'center'}}>
            <Alert style={{ width: '100%' }} color={this.state.alertStatus} isOpen={this.state.alert} toggle={this.onDismiss.bind(this)}>
              { this.state.alertContents }
            </Alert>
          </div>
        }

        <Application 
          fetchData={this.fetchData.bind(this)}
          setState={this.setState.bind(this)}
          state={this.state}
          error={error}
          organizations={organizations} 
          order={order} 
          loading={this.check_load()} 
          {...this.props} 
        />
      </div>
    );
  }

}

ApplicationPage.contextTypes = {
  router: PropTypes.object.isRequired
};

export default ApplicationPage;