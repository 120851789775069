import React from 'react';
import moment from 'moment';

export default class ApplicationStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {

    const { order, t } = this.props;

    let statusColor;
    let statusText;
    if (order.status === undefined){
      statusColor = "#fed713";
      statusText = t['dashboard.application.waiting_for_approval'];      
    } else if (!order.status){
      statusColor = "#fe7c96";
      statusText = t['dashboard.application.rejected'];       
    } else if (order.status && !order.paymentAt){
      statusColor = "#fed713";
      statusText = t['dashboard.application.unpaid']; 
    } else if (order.status && order.terminatedAt){
      statusColor = "#fe7c96";
      statusText = "Terminated";        
    } else if (order.status && moment().isAfter(order.end_date) ){
      statusColor = "#ccc";
      statusText = t['dashboard.application.expired']; 
    } else {
      statusColor = "#1bcfb4";
      statusText = t['dashboard.application.paid'];       
    }

    return (
      <div style={{  width: '100%', padding: "4px 0", display: 'flex', marginBottom: 9, alignItems: 'center' }}>
        <span style={{ paddingRight: "5px" }}>{t['confirm.status.title']}: </span>
        <i className="fa fa-circle" style={{ color: statusColor, fontSize: 10 }}/>
        <span style={{ paddingLeft: "5px" }}>{statusText}</span>
      </div>   
    );
  }

}
