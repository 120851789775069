import React from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';



import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { PictureAsPdf } from '@material-ui/icons';

import moment from 'moment';

import Lang from '../modules/Lang';

import { BASE_URL } from '../consts';
import Auth from './../modules/Auth';

const mapStateToProps = (state) => {
  return {
    storage: state.storage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  
});

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
  },
});


const getStatus = (n, t) => {
  
  let statusColor;
  let statusText;

  if (n.status === undefined){
    statusColor = "#fed713";
    statusText = t['dashboard.application.waiting_for_approval'];      
  } else if (!n.status){
    statusColor = "#fe7c96";
    statusText = t['dashboard.application.rejected'];       
  } else if (n.status && !n.paymentAt){
    statusColor = "#fed713";
    statusText = t['dashboard.application.unpaid']; 
  } else if (n.status && n.terminatedAt){
    statusColor = "#fe7c96";
    statusText = "Terminated";  
  } else if (n.status && moment().isAfter(n.end_date) ){
    statusColor = "#ccc";
    statusText = t['dashboard.application.expired']; 
  } else {
    statusColor = "#1bcfb4";
    statusText = t['dashboard.application.paid'];       
  }  

  return { statusColor, statusText }
}

function descendingComparator(a, b, orderBy) {
  // 'product_type', 'project_number', 'start_date', 'contractor', 'policy', 'invoice', 'status'
  let _a = a[orderBy];
  let _b = b[orderBy];

  // console.log(a, b, orderBy)

  if (orderBy === 'product_type'){ _a = a.type; _b = b.type; }
  else if (orderBy === 'project_number'){ _a = a.contract.project_number; _b = b.contract.project_number; }
  else if (orderBy === 'start_date'){ _a = a.start_date; _b = b.start_date; }
  else if (orderBy === 'contractor'){ _a = a.contractor.name; _b = b.contractor.name; }
  else if (orderBy === 'subcontractor'){ _a = a.subcontractor && a.subcontractor.name; _b = b.subcontractor && b.subcontractor.name; }
  else if (orderBy === 'policy'){ _a = a.policy && a.policy.originalname; _b = a.policy && b.policy.originalname; }
  else if (orderBy === 'invoice'){ _a = a.invoice && a.invoice.originalname; _b = a.invoice && b.invoice.originalname; }

  if (_b < _a) {
    return -1;
  }
  if (_b > _a) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme, storage } = this.props;
    const { language } = storage;
    const t = Lang[language];

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label={t['dashboard.aria.first_page']}
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label={t['dashboard.aria.prev_page']}
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label={t['dashboard.aria.next_page']}          
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label={t['dashboard.aria.last_page']}          
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}


const TablePaginationActionsWrapped = 
  connect( mapStateToProps, mapDispatchToProps)
  (withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
  ));


const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
    "boxShadow": 'none'
  },
  table: {
    minWidth: 700,
  },
  icon: {
    width: 25, 
    height: 25, 
    color: "#28429e"
  }, 
  buttonType: {
    "text-transform": "none", 
    color: "#28429e", 
    fontSize: 10
  },  
  link: {
    fontWeight: 400,
    textTransform: "none",
    color: "#28429e", 
    fontSize: 10    
  }
};


const headCells = [
  { key: 'product_type', disablePadding: true, style: { padding: "4px 20px", textAlign: "left", width: '20%', maxWidth: '20rem' } },
  { key: 'project_number', disablePadding: true, style: { padding: "4px 20px", textAlign: "left", width: '14.5%', maxWidth: '8rem' }  },
  { key: 'start_date', disablePadding: true, style: { padding: "4px 20px", textAlign: "left", width: '8.5%', maxWidth: '8rem' }  },
  { key: 'contractor', disablePadding: true, style: { padding: "4px 20px", textAlign: "left", width: '12.5%', maxWidth: '8rem' }  },
  { key: 'subcontractor', disablePadding: true, style: { padding: "4px 20px", textAlign: "left", width: '12.5%', maxWidth: '8rem' }  },
  { key: 'policy', disablePadding: true, style: { padding: "4px 20px", textAlign: "left", width: '12.5%', maxWidth: '8rem' } },
  { key: 'invoice', disablePadding: true, style: { padding: "4px 20px", textAlign: "left", width: '12.5%', maxWidth: '8rem' } },
  { key: 'status', disablePadding: true, style: { padding: "4px 20px", textAlign: "left", width: '12.5%', maxWidth: '8rem' } },
];



function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.key}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.key ? order : false}
            style={headCell.style}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : 'asc'}
              onClick={createSortHandler(headCell.key)}
              IconComponent={KeyboardArrowDown}
            >
              {t['dashboard.application.' + headCell.key]}
              {orderBy === headCell.key ? (
                <span style={{
                  border: 0,
                  clip: 'rect(0 0 0 0)',
                  height: 1,
                  margin: -1,
                  overflow: 'hidden',
                  padding: 0,
                  position: 'absolute',
                  top: 20,
                  width: 1,
                }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



class ApplicationTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 5,
      order: 'asc',
      orderBy: 'product_type'
    }    
  }  

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy, } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  render(){
    const h = this.context.router.history;    
    const { classes, applications, storage } = this.props;
    const { page, rowsPerPage, order, orderBy, } = this.state;

    const { language } = storage;
    const t = Lang[language];    


    // console.log(order, orderBy,)

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>

          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
            t={t}
          />

          <TableBody>
            {stableSort(applications, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {

              let statusColor;
              let statusText;
              if (n.status === undefined){
                statusColor = "#fed713";
                statusText = t['dashboard.application.waiting_for_approval'];      
              } else if (!n.status){
                statusColor = "#fe7c96";
                statusText = t['dashboard.application.rejected'];       
              } else if (n.status && !n.paymentAt){
                statusColor = "#fed713";
                statusText = t['dashboard.application.unpaid']; 
              } else if (n.status && n.terminatedAt){
                statusColor = "#fe7c96";
                statusText = "Terminated";  
              } else if (n.status && moment().isAfter(n.end_date) ){
                statusColor = "#ccc";
                statusText = t['dashboard.application.expired']; 
              } else {
                statusColor = "#1bcfb4";
                statusText = t['dashboard.application.paid'];       
              }

              return <TableRow key={n._id} >
                
                <TableCell align="center" style={{ width: '20%', maxWidth: '20%', padding: "10px 20px", textAlign: "left", color: "#28429e", cursor: 'pointer',  overflow: "hidden", "textOverflow": "ellipsis" }} component="th" scope="row" onClick={ () => h.push(`/account/application/${n._id}`) } >
                  {n.type === "Fullgörande" ? t['confirm.summary.while_contract.type_name'] : n.type === "Garantitid" ? t['confirm.summary.while_warranty.type_name'] : ""}
                </TableCell>
                
                <TableCell align="center" style={{ width: '12.5%', maxWidth: '8rem', padding: "0 20px", textAlign: "left", overflow: "hidden", "textOverflow": "ellipsis" }} >{n.contract.project_number}</TableCell>
                <TableCell align="center" style={{ width: '12.5%', maxWidth: '8rem', padding: "0 20px", textAlign: "left", overflow: "hidden", "textOverflow": "ellipsis" }} >{moment(n.start_date).format("YYYY-MM-DD")}</TableCell>
                <TableCell align="center" style={{ width: '12.5%', maxWidth: '8rem', padding: "0 20px", textAlign: "left", overflow: "hidden", "textOverflow": "ellipsis" }} >{n.contractor.name}</TableCell>

                <TableCell align="center" style={{ width: '12.5%', maxWidth: '8rem', padding: "0 20px", textAlign: "left", overflow: "hidden", "textOverflow": "ellipsis" }} >{n.subcontractor && n.subcontractor.name || 'N/A'}</TableCell>

                <TableCell align="center" style={{ width: '12.5%', maxWidth: '8rem', padding: "0 20px", textAlign: 'left', overflow: "hidden", "textOverflow": "ellipsis", lineHeight: 1 }} >

                  { n.policy ?
                    <Grid align="center" onClick={ () => window.open(`${BASE_URL}/auth/policies/${n.policy.filename}?token=${Auth.getToken()}`, '_blank' ) } style={{ cursor: 'pointer' }}>
                      <i className="far fa-file-pdf fa-2x" style={{ color: "#28429e" }} />
                      <Typography className={classes.link}>
                        {n.policy.originalname}
                      </Typography>
                    </Grid>
                  : 'N/A' } 


      

                </TableCell>
                <TableCell align="center" style={{ width: '12.5%', maxWidth: '8rem', padding: "0 20px", textAlign: "left", overflow: "hidden", "textOverflow": "ellipsis" }} >
                   
                  { n.invoice ?
                    <Grid align="center" onClick={ () => window.open(`${BASE_URL}/auth/invoices/${n.invoice.filename}?token=${Auth.getToken()}`, '_blank') } style={{ cursor: 'pointer' }}>
                      <i className="far fa-file-pdf fa-2x" style={{ color: "#28429e" }} />
                      <Typography className={classes.link}>
                        {n.invoice.originalname}
                      </Typography>
                    </Grid>
                  : 'N/A' } 

                </TableCell>
                <TableCell align="center" style={{ width: '12.5%', maxWidth: '8rem', padding: "0 20px", textAlign: "left", overflow: "hidden", "textOverflow": "ellipsis" }} >
                  <i className="fa fa-circle" style={{ color: statusColor }}/>
                  <span style={{ paddingLeft: "5px" }}>{ statusText }</span>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={t["dashboard.application.row_per_page_label"] || ""}
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={7}
                count={applications.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}

              />
            </TableRow>
          </TableFooter>          
        </Table>
      </Paper>
    );
  }
}

ApplicationTable.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(  
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(ApplicationTable));